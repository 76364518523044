import type { VAnimatedObject, VFrame, VSequence } from "three-modules";
import type { VideoDefinitionStore } from "./videoDefinitionStore";

export type WithId<T> = T & { id: string };

export type StoreAnimatedObject = { id: string } & VAnimatedObject;
export type StoreSequence = {
  id: string;
  objects: StoreAnimatedObject[];
} & VSequence;
export type StoreFrame = {
  id: string;
  elements: StoreSequence[][][];
} & VFrame;

export const getWithoutId = <T extends { id: string }>(object: T) => {
  const { id, ...other } = object;
  return other;
};

export const STARTID = "startId";
export const SOUND = "sound";

export enum ObjectTypes {
  "frame" = "frame",
  "sequence" = "sequence",
  "object" = "object",
  "file" = "file",
}

export const getObjectFrameId = (store: VideoDefinitionStore, id: string) => {
  const sequenceId = store.getObject(id).sequenceId;
  const frameId = store.getObject(sequenceId).frameId;
  return frameId;
};
