import type { ReactNode } from "react";
import styled from "styled-components";
import { Input } from "../@/components/ui/input";
import { Label } from "../@/components/ui/label";
import { Slider } from "../@/components/ui/slider";
import { Checkbox } from "../components/Checkbox";
import MultiSelectorCheckbox, {
  IconListCheckboxItem,
} from "../components/IconListCheckbox";
import NumberInput from "../components/NumberInput";
import { Select } from "../components/Select";
import { ObjectTypes } from "../store/storeUtils";
import { blobToBase64 } from "../utils/base64Utils";
import ColorEditor from "./ColorEditor";
import type { useStore } from "./VideoDefinitionStoreProvider";

const StyledTextarea = styled.textarea`
  width: 100%;
  resize: none;
  border-color: #d8d8d8;
  border-radius: 4px;
  font-family: "Poppins-Regular";
`;

export enum EditorPropertyType {
  "color" = "color",
  "number" = "number",
  "file" = "file",
  "text" = "text",
  "slider" = "slider",
  "checkbox" = "checkbox",
  "select" = "select",
  "multicheckbox" = "multicheckbox",
}
type SelectItem = { label: string; id: string };

interface PropertyChange<T, K = T> {
  label: string;
  isMain?: boolean;
  store: ReturnType<typeof useStore>;
  value: T;
  onChange: (t: K) => void;
}

export const editorMap: Record<
  EditorPropertyType,
  (t: PropertyChange<any>) => ReactNode
> = {
  [EditorPropertyType.color]: ({
    label,
    value,
    onChange,
  }: PropertyChange<string>) => (
    <>
      <Label>{label}</Label>
      <ColorEditor value={value ?? "black"} onChange={onChange}></ColorEditor>
    </>
  ),
  [EditorPropertyType.checkbox]: ({
    label,
    value,
    onChange,
  }: PropertyChange<boolean>) => (
    <>
      <div></div>
      <Checkbox
        label={label}
        value={Boolean(value) ?? false}
        onChange={() => onChange(!(value ?? false))}
      />
    </>
  ),
  [EditorPropertyType.multicheckbox]: ({
    label,
    value,
    onChange,
  }: PropertyChange<
    { items: IconListCheckboxItem[]; selectedIds: string[] },
    string[]
  >) => (
    <>
      <Label>{label}</Label>
      <MultiSelectorCheckbox
        items={value.items}
        selectedIds={value.selectedIds}
        onChange={(ids) => onChange(ids)}
      ></MultiSelectorCheckbox>
    </>
  ),
  [EditorPropertyType.select]: ({
    label,
    value,
    onChange,
  }: PropertyChange<{ items: SelectItem[]; selectedId: string }, string>) => (
    <>
      <Label>{label}</Label>
      <Select
        value={value.selectedId}
        items={value.items}
        onChange={(v) => onChange(v)} // ... and update the state variable on any change!
      ></Select>
    </>
  ),
  [EditorPropertyType.number]: ({
    label,
    value,
    onChange,
  }: PropertyChange<number>) => (
    <>
      <Label>{label}</Label>
      <NumberInput value={value} onChange={() => onChange(value)} />
    </>
  ),
  [EditorPropertyType.text]: ({
    label,
    isMain,
    value,
    onChange,
  }: PropertyChange<string>) => {
    return (
      <>
        <Label>{label}</Label>
        <Input
          value={value ?? ""}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        ></Input>
      </>
    );
  },
  [EditorPropertyType.file]: ({
    label,
    value,
    store,
    onChange,
  }: PropertyChange<string>) => {
    return (
      <>
        <Label htmlFor={label}>{label}</Label>
        <Input
          onChange={(e) => {
            const onChangeHandler = async (files: FileList | null) => {
              if (files !== null) {
                const base64 = (await blobToBase64(files[0])) as string;
                const id = store.createObject({
                  type: ObjectTypes.file,
                  value: base64,
                });
                onChange(id);
              }
            };
            onChangeHandler(e.target.files);
          }}
          id={label}
          type="file"
          accept="image/*"
        />
      </>
    );
  },
  [EditorPropertyType.slider]: ({
    label,
    value,
    onChange,
  }: PropertyChange<number>) => {
    return (
      <>
        <Label>{label}</Label>
        <Slider
          value={[value ?? 12]}
          onValueChange={(e) => {
            onChange(e[0]);
          }}
          className={"w-full"}
          min={0}
          max={200}
          step={1}
        />
      </>
    );
  },
};
