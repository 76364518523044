import { useState } from "react";
import styled from "styled-components";
import type { VideoDefinition } from "three-modules";
import { FeedbackDialog } from "../app-components/FeedbackDialog";
import { DropdownMenu } from "../components/DropdownMenu";
import Icon, { IconName } from "../components/icons";
import { Tabs } from "../components/Tabs";
import { Toggle } from "../components/Toggle";

const HeaderContainer = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
`;

const DurationContainer = styled.div`
  width: 170px;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

interface TopHeaderProps {
  durationInMs: number;
  onTabChange: (tab: string) => void;
  tab: string;
  tabs: { label: string; key: string }[];
  onSet: (data: VideoDefinition) => void;
}

const TopHeader = ({
  durationInMs,
  onSet,
  tab,
  tabs,
  onTabChange,
}: TopHeaderProps) => {
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  return (
    <>
      {isFeedbackOpen && <FeedbackDialog onOpenChange={setIsFeedbackOpen} />}
      <HeaderContainer>
        <>
          <DropdownMenu onSet={onSet} />
        </>

        <Tabs tab={tab} tabs={tabs} onChange={(key) => onTabChange(key)}></Tabs>

        <DurationContainer>
          <p className="text-sm text-muted-foreground">
            Duration: {durationInMs / 1000}s
          </p>
          <Toggle value={false} onChange={() => setIsFeedbackOpen(true)}>
            <Icon name={IconName.help} size={16}></Icon>
          </Toggle>
        </DurationContainer>
      </HeaderContainer>
    </>
  );
};

export default TopHeader;
