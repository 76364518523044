import type { ReactNode } from "react";
import { Sequence, useCurrentFrame, useVideoConfig } from "remotion";
import type { Transition } from "../../types/types";
import { Easing, getTwin } from "../../utils/animationUtils";
import type { TimingProps } from "../../utils/timings";
import { getTransitionClipPath } from "../../utils/transitions";
import OpenCurtain from "../transitions/OpenCurtain";

interface SequenceBaseProps {
  timing: Required<TimingProps>;
  zIndex?: number;
  background?: string;
  backgroundImage?: string;
  endTransition?: Transition;
  children: ReactNode;
}
const SequenceBase = ({
  timing,
  zIndex = 0,
  background,
  backgroundImage,
  endTransition,
  children,
}: SequenceBaseProps) => {
  const fadeOutOpacity = getTwin(
    1,
    0,
    timing.from + timing.duration - 330,
    timing.from + timing.duration,
    Easing.linear
  );

  const { fps } = useVideoConfig();
  const frame = useCurrentFrame();
  const ms = (frame / fps) * 1000;
  return (
    <>
      <Sequence
        durationInFrames={(timing.duration * fps) / 1000}
        from={(timing.from * fps) / 1000}
        style={{
          display: "flex",
          fontSize: "1em",
          justifyContent: "center",
          alignItems: "center",
          zIndex: zIndex,
          background: background,
          backgroundSize: "cover",
          ...(backgroundImage
            ? {
                backgroundImage: `url('https://images.unsplash.com/photo-1504201986385-9ace376ac112?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80')`,
              }
            : {}),
          opacity: endTransition === "fadeOut" ? fadeOutOpacity(ms) : 1,
          clipPath: endTransition
            ? getTransitionClipPath(`${zIndex}`)
            : undefined,
        }}
      >
        {children}
      </Sequence>
      {endTransition && (
        <>
          {endTransition === "openCurtain" && (
            <OpenCurtain
              clipId={`${zIndex}`}
              duration={timing.duration}
              from={timing.from}
            />
          )}
        </>
      )}
    </>
  );
};

export default SequenceBase;
