export const EVENT_NAME = {
  ATEMPTED_EXPORT: "ATEMPTED_EXPORT",
};

export const trackEvent = async (
  eventName: string,
  properties: Record<string, unknown>
) => {
  if (process.env.REACT_APP_ENV === "production") {
    await fetch("/api/track", {
      method: "POST",
      body: JSON.stringify({ eventName, properties }),
      headers: { "Content-Type": "application/json" },
    });
  } else {
    console.log({ eventName, properties });
  }
};
