import styled from "styled-components";
import SequenceBase from "../../remotion/sequences/SequenceBase";
import Spot from "../../remotion/sequences/Spot";
import type { TimingProps } from "../../utils/timings";
import type { FileRecord, VFrame } from "../types";

interface FrameLayoutProps {
  frame: VFrame;
  timing: Required<TimingProps>;
  zIndex: number;
}

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
const Line = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: end;
  justify-content: center;
`;
const FrameLayout = ({ frame, timing, zIndex }: FrameLayoutProps) => {
  const lines = frame.elements;
  return (
    <SequenceBase
      timing={{
        duration: timing.duration,
        from: timing.from,
      }}
      zIndex={zIndex}
      background={frame.background}
    >
      <LayoutContainer>
        {lines.map((columns, index) => (
          <Line key={index + "" + columns.length}>
            {columns
              .filter(
                (columnSequence) =>
                  columnSequence !== undefined && columnSequence !== null
              )
              .map((columnSequence, columnIndex) => (
                <Spot
                  key={index + "-" + columnIndex}
                  sequences={columnSequence}
                />
              ))}
          </Line>
        ))}
      </LayoutContainer>
    </SequenceBase>
  );
};

export default FrameLayout;
