import { ObjectTypes } from "../store/storeUtils";
import ObjectEditor from "./ObjectEditor";
import { useStore } from "./VideoDefinitionStoreProvider";

export type SelectionType = "sequence" | "object" | "spot";

interface SelectionEditorProps {
  selectionId: string;
}

const SelectionEditor = ({ selectionId }: SelectionEditorProps) => {
  const store = useStore();
  const selection = store.getObject(selectionId);

  if (selection && selection.type === ObjectTypes.object) {
    const object = selection.data;
    return (
      <>
        <ObjectEditor
          key={selection.id + "-editor"}
          object={object}
          onObjectChange={(v) => {
            store.updateObject(selection.id as string, {
              ...selection,
              data: v,
            });
          }}
        />
      </>
    );
  }

  return null;
};

export default SelectionEditor;
