import type { PropertyAnimation } from "../types/types";
import { Easing } from "./animationUtils";
import { DEFAULT_ANIMATION_DURATION, TimingProps } from "./timings";

export enum PreBuiltAnimationType {
  "enterUp" = "enterUp",
  "enterScaleDown" = "enterScaleDown",
  "enterJump" = "enterJump",
  "enterScaleUp" = "enterScaleUp",
  "fadeIn" = "fadeIn",
  "shake" = "shake",
  //Only text
  "letterSpacingScaleDown" = "letterSpacingScaleDown",
  "letterSpacingScaleUp" = "letterSpacingScaleUp",
  //Only image
  "threeDTransformation" = "threeDTransformation",
}

export interface PrebuiltProps {
  timing?: TimingProps; // USE TIMELINE TIMINGS INSTEAD
  startY?: number; // enterUp
  objectHeight?: number; //shake
  easing?: Easing;
}

interface PrebuiltAnimation {
  label: string;
  types?: string[];
  animation: (props: PrebuiltProps) => PropertyAnimation[];
}

export const preBuiltAnimations: Record<
  PreBuiltAnimationType,
  PrebuiltAnimation
> = {
  [PreBuiltAnimationType.enterUp]: {
    label: "Enter Up",
    animation: ({ timing, startY, easing }: PrebuiltProps) => [
      {
        property: "y",
        timings: [
          {
            time: timing?.from ?? 0,
            value: startY ?? 200,
            easing: easing ?? Easing.easeOutQuad,
          },
          {
            time:
              (timing?.from ?? 0) +
              (timing?.duration ?? DEFAULT_ANIMATION_DURATION),
            value: 0,
          },
        ],
      },
    ],
  },
  [PreBuiltAnimationType.enterJump]: {
    label: "Enter Jump",
    animation: ({ timing, startY, easing }: PrebuiltProps) => [
      {
        property: "y",
        timings: [
          {
            time: timing?.from ?? 0,
            value: startY ?? 200,
            easing: easing ?? Easing.easeOutBack,
          },
          {
            time:
              (timing?.from ?? 0) +
              (timing?.duration ?? DEFAULT_ANIMATION_DURATION),
            value: 0,
            easing: easing ?? Easing.easeOutQuad,
          },
        ],
      },
    ],
  },
  [PreBuiltAnimationType.enterScaleDown]: {
    label: "Enter Scale Down",
    animation: ({ timing, easing }: PrebuiltProps) => [
      {
        property: "scale",
        timings: [
          {
            time: timing?.from ?? 0,
            value: 1.7,
            easing: easing ?? Easing.easeOutQuad,
          },
          {
            time:
              (timing?.from ?? 0) +
              (timing?.duration ?? DEFAULT_ANIMATION_DURATION),
            value: 1,
            easing: easing ?? Easing.easeOutQuad,
          },
        ],
      },
    ],
  },
  [PreBuiltAnimationType.enterScaleUp]: {
    label: "Enter Scale Up",
    animation: ({ timing, easing }: PrebuiltProps) => [
      {
        property: "scale",
        timings: [
          {
            time: timing?.from ?? 0,
            value: 0.5,
            easing: easing ?? Easing.easeOutQuad,
          },
          {
            time:
              (timing?.from ?? 0) +
              (timing?.duration ?? DEFAULT_ANIMATION_DURATION),
            value: 1,
          },
        ],
      },
    ],
  },
  [PreBuiltAnimationType.fadeIn]: {
    label: "Fade In",
    animation: ({ timing, easing }: PrebuiltProps) => [
      {
        property: "opacity",
        timings: [
          {
            time: timing?.from ?? 0,
            value: 0,
            easing: easing ?? Easing.easeOutQuad,
          },
          {
            time:
              (timing?.from ?? 0) +
              (timing?.duration ?? DEFAULT_ANIMATION_DURATION),
            value: 1,
          },
        ],
      },
    ],
  },
  [PreBuiltAnimationType.shake]: {
    label: "Shake",
    animation: ({ timing, objectHeight }: PrebuiltProps) => {
      const shakeStep = ((objectHeight ?? 100) * 1) / 10;
      const from = timing?.from ?? 0;
      const duration = timing?.duration ?? DEFAULT_ANIMATION_DURATION * 3;
      return [
        {
          property: "x",
          timings: [
            { time: from + 0 * duration, value: -shakeStep / 6 },
            { time: from + 0.5 * duration, value: 0 },
            { time: from + 0.6 * duration, value: -shakeStep / 6 },
          ],
        },
        {
          property: "y",
          timings: [
            { time: from + 0 * duration, value: -shakeStep / 6 - shakeStep },
            { time: from + 0.3 * duration, value: -shakeStep },
            { time: from + 0.5 * duration, value: 0 },
            {
              time: from + 0.65 * duration,
              value: (shakeStep * 2) / 3 - shakeStep,
            },
            { time: from + 0.9 * duration, value: 0 },
          ],
        },
      ];
    },
  },
  [PreBuiltAnimationType.letterSpacingScaleDown]: {
    label: "Letter Spacing Scale Down",
    types: ["text"],
    animation: ({ timing, easing }: PrebuiltProps) => {
      return [
        {
          property: "letter-spacing",
          timings: [
            {
              time: timing?.from ?? 0,
              value: 10,
              easing: easing ?? Easing.easeOutQuad,
            },
            {
              time:
                (timing?.from ?? 0) +
                (timing?.duration ?? DEFAULT_ANIMATION_DURATION),
              value: 0,
            },
          ],
        },
      ];
    },
  },
  [PreBuiltAnimationType.letterSpacingScaleUp]: {
    label: "Letter Spacing Scale Up",
    types: ["text"],
    animation: ({ timing, easing }: PrebuiltProps) => {
      return [
        {
          property: "letter-spacing",
          timings: [
            {
              time: timing?.from ?? 0,
              value: 0,
              easing: easing ?? Easing.easeOutQuad,
            },
            {
              time:
                (timing?.from ?? 0) +
                (timing?.duration ?? DEFAULT_ANIMATION_DURATION),
              value: 10,
            },
          ],
        },
      ];
    },
  },
  [PreBuiltAnimationType.threeDTransformation]: {
    label: "3d transformation",
    types: ["image"],
    animation: ({ timing, easing }: PrebuiltProps) => {
      return [
        {
          property: "x",
          timings: [{ time: 0, value: 21 }],
        },
        {
          property: "y",
          timings: [
            { time: 0, value: 76 },
            { time: 4000, value: -5 },
          ],
        },
        {
          property: "scale",
          timings: [
            { time: 0, value: 1.4 },
            { time: 4000, value: 1.3 },
          ],
        },
        {
          property: "transform-rotate3d-x",
          timings: [{ time: 0, value: 0.3 }],
        },
        {
          property: "transform-rotate3d-y",
          timings: [{ time: 0, value: 1 }],
        },
        {
          property: "transform-rotate3d-z",
          timings: [{ time: 0, value: -0.2 }],
        },
        {
          property: "transform-rotate3d-a",
          timings: [
            { time: 0, value: 37 },
            { time: 4000, value: 9 },
          ],
        },
      ];
    },
  },
};
