import { Button } from "../@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../@/components/ui/dialog";
import Icon, { IconName } from "../components/icons";

interface ReplaceWarningDialogProps {
  onOpenChange: (v: boolean) => void;
  onCancel: () => void;
  onReplace: () => void;
  isLoading:boolean;
}

export const ReplaceWarningDialog = ({
  onOpenChange,
  onReplace,
  onCancel,
  isLoading = false,
}: ReplaceWarningDialogProps) => {
  return (
    <Dialog open onOpenChange={(v) => (!v ? onCancel() : onOpenChange(v))}>
      <DialogContent className="sm:max-w-[625px]">
        <DialogHeader>
          <DialogTitle>Demo link</DialogTitle>
          <DialogDescription>You are loading a demo link</DialogDescription>
        </DialogHeader>
        <div className="w-full space-y-4">
          <p>
            You can choose to <b>reset</b> your video to the initial data or{" "}
            <b>continue</b> with current data.
          </p>
          <div className="flex gap-4">
            <Button onClick={onReplace}>
              {isLoading && (
                <>
                  <div className="mr-2 animate-spin">
                    <Icon name={IconName.wait} size={20}></Icon>
                  </div>
                  Please wait
                </>
              )}
              {!isLoading && <>Reset video</>}
            </Button>
            <Button onClick={onCancel} variant="secondary">
              Continue editing
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
