import { getTemplates } from "three-modules";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../@/components/ui/dialog";
import { Label } from "../@/components/ui/label";
import TemplatePreview from "./TemplatePreview";

interface TemplatesDialogProps {
  onOpenChange: (v: boolean) => void;
  onTemplateClick: (templateKey: string) => void;
}
export const TemplateDialog = ({
  onOpenChange,
  onTemplateClick,
}: TemplatesDialogProps) => {
  return (
    <Dialog open onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[625px]">
        <DialogHeader>
          <DialogTitle>Choose an example</DialogTitle>
          <DialogDescription>
            Select an example then customize it.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-2 py-4 grid-cols-3">
          {Object.values(getTemplates()).map((template) => (
            <div key={template.key}>
              <Label>{template.title}</Label>
              <TemplatePreview
                template={template}
                onClick={() => {
                  onTemplateClick(template.key);
                  onOpenChange(false);
                }}
              />
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};
