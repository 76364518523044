import { forwardRef } from "react";

interface TextProps {
  text: string;
  font?: string;
  color?: string;
  fontSize?: number;
  lineHeight?: number;

  uppercase?: boolean;
  italic?: boolean;
}
export const Text = forwardRef<HTMLDivElement, TextProps>((props, ref) => {
  return (
    <span
      ref={ref}
      style={{
        color: props.color,
        fontSize: props.fontSize,
        fontStyle: props.italic ? "italic" : "normal",
        lineHeight: props.lineHeight ?? "100%",
        fontFamily: props.font,
        ...(props.uppercase ? { textTransform: "uppercase" } : {}),
      }}
    >
      {props.text}
    </span>
  );
});