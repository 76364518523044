import type { VAnimatedObject } from "three-modules";
import { ObjectTypes, STARTID } from "../store/storeUtils";
import type { VideoDefinitionStore } from "../store/videoDefinitionStore";
import { getObjectsForSequence } from "./store2VideoDefinition";

export const DEFAULT_FRAME_DATA = { background: "#ffffff", duration: 2000 };
interface RichText2StoreProps {
  store: VideoDefinitionStore;
  jsonValue: string;
}

interface CreateSeq {
  store: VideoDefinitionStore;
  line: number;
  seqId?: string;
  column: number;
  frameId: number;
}
const createSequenceForFrameElement = ({
  seqId,
  store,
  line,
  column,
  frameId,
}: CreateSeq) => {
  if (seqId) {
    store.updateObject(seqId, {
      type: ObjectTypes.sequence,
      data: {},
      frameId,
      position: [line, column],
    });
    return seqId;
  } else {
    const sequenceId = store.createObject({
      type: ObjectTypes.sequence,
      data: {},
      frameId,
      position: [line, column],
    });
    return sequenceId;
  }
};

interface ProcessJsonObjectProps {
  store: VideoDefinitionStore;
  frameId: number;
  position: [number, number];
  jsonObject: any;
  getSequenceForNewObject?: () => string;
}
const processJsonObject = ({
  store,
  frameId,
  position,
  jsonObject,
  getSequenceForNewObject,
}: ProcessJsonObjectProps) => {
  const [line, column] = position;
  const objectId = jsonObject.objectData.id;
  const exitingObject = store.getObject(jsonObject.objectData.id);

  let objectData: VAnimatedObject = {
    type: "text",
    props: {
      text: "Not supported object",
    },
    animations: {},
  };
  if (jsonObject.type === "custom-text") {
    objectData = exitingObject
      ? {
          ...exitingObject.data,
          props: {
            ...exitingObject.data.props,
            text: jsonObject.text,
          },
        }
      : {
          type: "text",
          props: {
            text: jsonObject.text,
            color: "#000000",
          },
          animations: {},
        };
  }
  if (jsonObject.type === "image") {
    objectData = exitingObject
      ? {
          ...exitingObject.data,
          props: {
            ...exitingObject.data.props,
            value: jsonObject.value,
            imageType: jsonObject.imageType,
            ...(jsonObject.width ? { width: jsonObject.width } : {}),
            ...(jsonObject.height ? { height: jsonObject.height } : {}),
          },
        }
      : {
          type: "image",
          props: {
            value: jsonObject.value,
            imageType: jsonObject.imageType,
          },
          animations: {},
        };
  }

  const isNewObject = !exitingObject;

  if (isNewObject) {
    let sequenceId: string;
    sequenceId = createSequenceForFrameElement({
      seqId: getSequenceForNewObject?.() ?? undefined,
      store,
      frameId,
      line,
      column,
    });
    store.updateObject(objectId, {
      type: ObjectTypes.object,
      sequenceId: sequenceId,
      data: objectData,
    });
  } else {
    let sequenceId = exitingObject.sequenceId;
    let existingObjectFrameId = store.getObject(sequenceId).frameId;
    const hasChangedSequence = existingObjectFrameId !== frameId;
    if (hasChangedSequence) {
      sequenceId = createSequenceForFrameElement({
        store,
        frameId,
        line,
        column,
      });
    }
    const hasChangedPosition =
      store.getObject(sequenceId).position[0] !== line ||
      store.getObject(sequenceId).position[1] !== column;
    if (hasChangedPosition) {
      store.updateObject(sequenceId, {
        type: ObjectTypes.sequence,
        data: {
          from: 0,
        },
        frameId,
        position: [line, column],
      });
    }
    store.updateObject(objectId, {
      type: "object",
      sequenceId: sequenceId,
      data: objectData,
    });
  }
};

export const richText2Store = ({ store, jsonValue }: RichText2StoreProps) => {
  let jsonObjectIds: string[] = [];
  let jsonFrameIds: string[] = [];

  const paragraphs = JSON.parse(jsonValue)?.root.children;
  // console.log({ paragraphs });
  let lastFrameId: string | undefined = undefined;

  paragraphs.forEach((paragraphChild: any) => {
    if (paragraphChild.type === "custom-paragraph") {
      jsonFrameIds = [...jsonFrameIds, paragraphChild.frameData.id];
      const isNewFrame = !store.getObject(paragraphChild.frameData.id);
      const frameId = paragraphChild.frameData.id;

      //New frame creation
      if (isNewFrame) {
        let nextId = store.getObject(STARTID)?.startId;
        let copyLastData: any = {};
        if (lastFrameId) {
          const lastFrame = store.getObject(lastFrameId);
          copyLastData = lastFrame.data;
          nextId = copyLastData.nextSequenceId;
        }
        //create new object
        store.updateObject(frameId, {
          type: ObjectTypes.frame,
          data: {
            ...DEFAULT_FRAME_DATA,
            ...copyLastData,
            nextSequenceId: nextId ?? undefined,
          },
        });

        // link previous object to next object
        if (lastFrameId) {
          const lastFrame = store.getObject(lastFrameId);
          const lastFrameData = lastFrame.data;
          store.updateObject(lastFrameId, {
            type: ObjectTypes.frame,
            data: {
              ...lastFrameData,
              nextSequenceId: frameId,
            },
          });
        } else {
          store.updateObject(STARTID, { startId: frameId });
        }
      }
      lastFrameId = frameId;

      const positions: Record<string, [number, number]> = {};
      let pLine = 0;
      let pColumn = -1;
      paragraphChild.children.forEach(
        (jsonSpot: any, jsonSpotIndex: number) => {
          if (jsonSpot.type === "linebreak") {
            pLine = pLine + 1;
            pColumn = -1;
          } else {
            pColumn = pColumn + 1;
          }
          if (jsonSpot.objectData && jsonSpot.objectData.id) {
            positions[jsonSpot.objectData.id] = [pLine, pColumn];
          }
        }
      );
      paragraphChild.children
        .filter((jsonSpot: any) => {
          if (jsonSpot.objectData && jsonSpot.objectData.id) {
            return true;
          }
          return false;
        })
        .forEach((jsonSpot: any) => {
          if (jsonSpot.type === "tablesheet") {
            const objects = jsonSpot.rows[0]?.cells.map((cell: any) => ({
              object: cell.data,
              cell,
            }));
            const [line, column] = positions[jsonSpot.objectData.id];
            objects
              .filter(
                ({ object }: any) => object !== null && object !== undefined
              )
              .forEach(({ object, cell }: any) => {
                jsonObjectIds = [...jsonObjectIds, object.objectData.id];
                processJsonObject({
                  store,
                  frameId,
                  position: [line, column],
                  jsonObject: object,
                  getSequenceForNewObject: () => {
                    return cell.id;
                  },
                });
              });
          } else {
            const [line, column] = positions[jsonSpot.objectData.id];
            jsonObjectIds = [...jsonObjectIds, jsonSpot.objectData.id];
            processJsonObject({
              store,
              frameId,
              position: [line, column],
              jsonObject: jsonSpot,
            });
          }
        });
    }
  });

  const objectIds = store.byType(ObjectTypes.object).map((o) => o.id);
  objectIds.forEach((id) => {
    if (!jsonObjectIds.includes(id)) {
      const sequenceId = store.getObject(id).sequenceId;
      const objects = getObjectsForSequence(store, sequenceId, false);
      if (objects.length === 1) {
        store.deleteObject(sequenceId);
      }
      store.deleteObject(id);
    }
  });
  const frameIds = store.byType(ObjectTypes.frame).map((o) => o.id);
  let idsToDelete: string[] = [];

  frameIds.forEach((id) => {
    if (!jsonFrameIds.includes(id)) {
      const toDelete = store.getObject(id);
      const previous = store
        .byType(ObjectTypes.frame)
        .find((v) => v.data.nextSequenceId === id);
      if (previous) {
        store.updateObject(previous.id, {
          ...previous,
          data: {
            ...previous.data,
            nextSequenceId: toDelete.data.nextSequenceId,
          },
        });
      } else if (toDelete.data.nextSequenceId) {
        store.updateObject(STARTID, {
          startId: toDelete.data.nextSequenceId,
        });
      }
      idsToDelete.push(id);
    }
  });
  idsToDelete.forEach((id) => {
    store.deleteObject(id);
  });
};
