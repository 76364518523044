import { Player } from "@remotion/player";
import styled from "styled-components";
import { AnimatedObject, PropertyAnimation } from "three-modules";

interface AnimationPreviewProps {
  animations: PropertyAnimation[];
}

const size = 33;
const PreviewContainer = styled.div`
  width: ${size}px;
  height: ${size}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: hsl(var(--muted));
  border-radius: 5px;
  overflow: hidden;
`;

const AnimatedBall = styled.div`
  width: 15px;
  height: 15px;
  background: hsl(var(--foreground));
  border-radius: 50px;
`;

const AnimationPreviewer = ({ animations }: AnimationPreviewProps) => (
  <PreviewContainer>
    <AnimatedObject animations={animations}>
      <AnimatedBall></AnimatedBall>
    </AnimatedObject>
  </PreviewContainer>
);

const AnimationPreview = ({ animations }: AnimationPreviewProps) => {
  return (
    <Player
      fps={50}
      inputProps={{ animations }}
      durationInFrames={180}
      compositionWidth={size}
      compositionHeight={size}
      autoPlay
      loop
      component={AnimationPreviewer}
    ></Player>
  );
};

export default AnimationPreview;
