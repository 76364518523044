import { Sequence, useVideoConfig } from "remotion";
import TransitionMask from "../components/TransitionMask";

interface OpenCurtainProps {
  from: number;
  duration: number;
  clipId: string;
}
const OpenCurtain = ({ clipId, from, duration }: OpenCurtainProps) => {
  const { fps } = useVideoConfig();
  return (
    <Sequence
      durationInFrames={1320}
      from={((from + duration - 660) * fps) / 1000}
    >
      <TransitionMask clipId={clipId} />
    </Sequence>
  );
};

export default OpenCurtain;
