import type { ReactNode } from "react";
import React from "react";
import styled from "styled-components";
import { Separator } from "../@/components/ui/separator";
import {
  Tabs as TabsBase,
  TabsList,
  TabsTrigger,
} from "../@/components/ui/tabs";
import Icon, { IconName } from "./icons";
import Tooltip from "./Tooltip";

interface TabsComponentProps {
  tab?: string;
  defaultKey?: string;
  isVertical?: boolean;
  children?: ReactNode;
  onChange?: (key: string) => void;
  tabs: {
    label?: string;
    icon?: IconName;
    iconTooltip?: string;
    key: string;
    component?: ReactNode;
    withSeparator?: boolean;
  }[];
}

const SelectorContainer = styled.div`
  margin: 0 auto;
  padding: 8px;
`;

export const Tabs = ({
  tab,
  tabs,
  defaultKey,
  children,
  isVertical,
  onChange,
}: TabsComponentProps) => {
  const elementsCount = tabs.reduce(
    (acc, current) => acc + (current.withSeparator ? 2 : 1),
    0
  );
  const classes = isVertical
    ? `h-full grid-rows-${elementsCount}`
    : `w-full grid-cols-${elementsCount}`;
  const sizeClass = isVertical ? undefined : "w-[250px]";
  const height = isVertical
    ? 28 * tabs.length + (elementsCount + 1) * 8
    : undefined;
  return (
    <TabsBase
      defaultValue={tab ?? defaultKey ?? tabs[0].key}
      value={tab ?? undefined}
      className="w-full"
      onValueChange={onChange}
    >
      <SelectorContainer className={sizeClass} style={{ height }}>
        <TabsList className={`grid ${classes}`}>
          {tabs.map(({ label, icon, key, iconTooltip, withSeparator }) => (
            <React.Fragment key={key}>
              <TabsTrigger value={key} key={key} isVertical={isVertical}>
                {label}
                {icon && (
                  <Tooltip tooltip={iconTooltip ?? ""}>
                    <Icon name={icon} size={20}></Icon>
                  </Tooltip>
                )}
              </TabsTrigger>
              {withSeparator && (
                <Separator orientation={isVertical ? undefined : "vertical"} />
              )}
            </React.Fragment>
          ))}
        </TabsList>
      </SelectorContainer>
      {children}
    </TabsBase>
  );
};
