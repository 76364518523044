import type { ReactNode } from "react";

import { Toggle as ToggleBase } from "../@/components/ui/toggle";

interface ToggleProps {
  children: ReactNode;
  value: boolean;
  onChange: (v: boolean) => void;
}
export const Toggle = ({ children, value, onChange }: ToggleProps) => {
  return (
    <ToggleBase pressed={value} onPressedChange={onChange} size="ssm">
      {children}
    </ToggleBase>
  );
};
// className="h-4 w-4"
