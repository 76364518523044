import useResizeObserver from "use-resize-observer";
import type { TextProps } from "../../types/animatedObjects/text";
import { Text } from "../components/Text";
import AnimatedRgbTextWrapper from "../components/text/AnimatedRgbTextWrapper";
import LetterByLetterWrapper from "../components/text/LetterByLetterWrapper";
import WordByWordScaleDown from "../components/text/WordByWordScaleDown";
import WordByWordWrapper from "../components/text/WordByWordWrapper";

interface TextAnimationProps {
  props: TextProps;
}

export const TextAnimation = ({ props }: TextAnimationProps) => {
  const {
    text,
    font,
    uppercase,
    italic,

    fontSize,
    lineHeight,
    color,
    withRgb,
    withLetterByLetter,
    withWordByWord,
    withWordByWordScaleDown,
  } = props;
  const { ref, width, height } = useResizeObserver();
  let result = (
    <Text
      ref={ref}
      text={text}
      font={font}
      fontSize={fontSize}
      lineHeight={lineHeight}
      uppercase={uppercase}
      italic={italic}
      color={withRgb ? "currentcolor" : color}
    />
  );

  if (withWordByWord) {
    result = (
      <WordByWordWrapper
        props={{
          ...withWordByWord,
          fontSize,
        }}
        text={text}
      >
        {(word) => (
          <Text
            ref={ref}
            text={word}
            font={font}
            fontSize={fontSize}
            lineHeight={lineHeight}
            uppercase={uppercase}
            italic={italic}
            color={withRgb ? "currentcolor" : color}
          />
        )}
      </WordByWordWrapper>
    );
  }

  if (withWordByWordScaleDown) {
    result = (
      <WordByWordScaleDown
        props={{
          ...withWordByWordScaleDown,
          fontSize,
        }}
        text={text}
      >
        {(word) => (
          <Text
            ref={ref}
            text={word}
            font={font}
            fontSize={fontSize}
            lineHeight={lineHeight}
            uppercase={uppercase}
            italic={italic}
            color={withRgb ? "currentcolor" : color}
          />
        )}
      </WordByWordScaleDown>
    );
  }

  if (withLetterByLetter) {
    result = (
      <LetterByLetterWrapper
        text={text}
        props={{
          ...withLetterByLetter,
        }}
      >
        {(letter) => (
          <Text
            ref={ref}
            text={letter}
            font={font}
            fontSize={fontSize}
            italic={italic}
            lineHeight={lineHeight}
            uppercase={uppercase}
            color={withRgb ? "currentcolor" : color}
          />
        )}
      </LetterByLetterWrapper>
    );
  }

  if (withRgb) {
    result = <AnimatedRgbTextWrapper>{result}</AnimatedRgbTextWrapper>;
  }

  return result;
};
