import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import { useLayoutEffect } from "react";
import { registerRichText } from "./utils/shortcutUtils";

interface ShortcutPluginProps {}
const ShortcutPlugin = ({}: ShortcutPluginProps) => {
  const [editor] = useLexicalComposerContext();

  useLayoutEffect(() => {
    return mergeRegister(registerRichText(editor));
  }, [editor]);
  return null;
};

export default ShortcutPlugin;
