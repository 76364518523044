import "./App.css";

import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";
import AppRoot from "./pages/AppRoot";

const router = createBrowserRouter([
  {
    path: "/",
    loader: ({ request }) => {
      const demo = new URL(request.url).searchParams.get("demo");
      if (demo) {
        return redirect(`/script?demo=${demo}`);
      }
      return redirect(`/script`);
    },
  },
  {
    path: "/:tab",
    element: <AppRoot />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
