"use client";

import { useRef } from "react";
import type { VideoDefinition } from "three-modules";

import { Button } from "../@/components/ui/button";
import {
  DropdownMenu as DropdownMenuBase,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../@/components/ui/dropdown-menu";
import { useTheme } from "../editors/ThemeProvider";
import { useStore } from "../editors/VideoDefinitionStoreProvider";
import { DEFAULT_FRAME_DATA } from "../transformers/richText2Store";
import { store2VideoDefinition } from "../transformers/store2VideoDefinition";
import { exportVideoLambda } from "../utils/videoExport";
import Icon, { IconName } from "./icons";

interface DropdownMenuProps {
  onSet: (data: VideoDefinition) => void;
}

const parseJsonFile = async (file: Blob) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) =>
      resolve(JSON.parse((event.target?.result as string) ?? ""));
    fileReader.onerror = (error) => reject(error);
    fileReader.readAsText(file);
  });
};

export const DropdownMenu = ({ onSet }: DropdownMenuProps) => {
  const inputFile = useRef<HTMLInputElement | null>(null);
  const store = useStore();
  const { theme, setTheme } = useTheme();

  const doExport = async () => {
    exportVideoLambda(store);
  };

  const onSaveToDisk = () => {
    const videoDefinition = store2VideoDefinition({ store });

    const download = (content: BlobPart, fileName: string) => {
      var a = document.createElement("a");
      var file = new Blob([content], { type: "application/json" });
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    };
    let filename = `obviouus_${new Date().toJSON().slice(0, 10)}.json`;
    download(JSON.stringify(videoDefinition) as BlobPart, filename);
  };
  const onButtonClick = (event: any) => {
    inputFile.current?.click();
  };
  const onFileChange = async (event: any) => {
    event.preventDefault();
    const object = (await parseJsonFile(event.target.files[0])) as any;
    onSet(object);
  };
  const doClear = async (event: any) => {
    onSet({ frames: [{ ...DEFAULT_FRAME_DATA, elements: [] }] });
  };
  return (
    <>
      <input
        type="file"
        accept="application/JSON"
        ref={inputFile}
        onChange={onFileChange}
        hidden
        // style={{ display: "none" }}
      />
      <DropdownMenuBase>
        <DropdownMenuTrigger>
          <Button variant="outline" size="icon">
            <Icon name={IconName.burger} size={20}></Icon>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" style={{ marginLeft: 8 }}>
          <DropdownMenuGroup>
            <DropdownMenuItem onSelect={onSaveToDisk}>
              Save as file
            </DropdownMenuItem>
            <DropdownMenuItem onSelect={onButtonClick}>
              Open from file...
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          {theme === "" && (
            <>
              <DropdownMenuItem onSelect={() => setTheme("dark")}>
                Dark mode
              </DropdownMenuItem>
              <DropdownMenuSeparator />
            </>
          )}
          {theme === "dark" && (
            <>
              <DropdownMenuItem onSelect={() => setTheme("")}>
                Light mode
              </DropdownMenuItem>
              <DropdownMenuSeparator />
            </>
          )}
          <DropdownMenuItem onSelect={doExport}>
            Download video
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem onSelect={doClear}>Clear all</DropdownMenuItem>
          {/* <DropdownMenuSeparator />
          <DropdownMenuItem disabled>Support</DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem>
            Log out
            <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
          </DropdownMenuItem> */}
        </DropdownMenuContent>
      </DropdownMenuBase>
    </>
  );
};
