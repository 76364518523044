import { Easing } from "../../utils/animationUtils";
import {
  preBuiltAnimations,
  PreBuiltAnimationType,
} from "../../utils/prebuiltAnimations";
import { registerTemplate } from "./templates";
registerTemplate({
  key: "text1",
  title: "Text appear",
  getFrames: () => [
    {
      duration: 1000,
      type: "horizontal",
      background: "purple",
      elements: [
        [
          [
            {
              objects: [
                {
                  type: "text",
                  props: {
                    text: "Showcase",
                    fontSize: 26,
                    font: "Montserrat-SemiBold",
                    uppercase: true,
                    color: "#fff",
                  },
                  animations: {
                    [PreBuiltAnimationType.letterSpacingScaleUp]:
                      preBuiltAnimations[
                        PreBuiltAnimationType.letterSpacingScaleUp
                      ].animation({
                        easing: Easing.easeOutCirc,
                        timing: { duration: 1000 },
                      }),
                    [PreBuiltAnimationType.enterScaleUp]: preBuiltAnimations[
                      PreBuiltAnimationType.enterScaleUp
                    ].animation({
                      easing: Easing.easeOutCirc,
                      timing: { duration: 1000 },
                    }),
                  },
                },
              ],
            },
          ],
        ],
      ],
    },
  ],
});
