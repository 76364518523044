import { TextAnimation } from "../../remotion/animations/TextAnimation";
import type { AnimatedLetterByLetter, AnimatedWordByWord } from "../types";
import { registerAnimatedObject } from "./animatedObjects";

export interface TextProps {
  text: string;
  font?: string;
  size?: string;
  color?: string;

  uppercase?: boolean;
  italic?: boolean;

  fontSize?: number;
  lineHeight?: number;

  withRgb?: boolean;
  withLetterByLetter?: AnimatedLetterByLetter;
  withWordByWord?: AnimatedWordByWord;
  withWordByWordScaleDown?: AnimatedWordByWord;
}

const fontItems = [
  { id: "Poppins-Light", label: "Poppins-Light" },
  { id: "Poppins-Regular", label: "Poppins-Regular" },
  { id: "Poppins-ExtraBold", label: "Poppins-ExtraBold" },
  { id: "Hikou", label: "Hikou" },
  { id: "Hikou-Outline", label: "Hikou-Outline" },
  { id: "BlinkMacSystemFont-Bold", label: "BlinkMacSystemFont-Bold" },
];

const styleItems = [
  { id: "uppercase" as keyof TextProps, icon: "uppercase" },
  { id: "italic" as keyof TextProps, icon: "italic" },
  { id: "underline" as keyof TextProps, icon: "underline" },
  { id: "code" as keyof TextProps, icon: "code" },
];

registerAnimatedObject<TextProps>({
  key: "text",
  title: "Text",
  getDisplay: (props) => <TextAnimation props={props}></TextAnimation>,
  getEditors: () => [
    {
      label: "Color",
      getValueFromProps: (props) => props["color"],
      setValueOnProps: (v, setPropertiesOnProps) =>
        setPropertiesOnProps({ color: v }),
      type: "color",
    },
    {
      label: "Font",
      getValueFromProps: (props) => {
        return {
          items: fontItems,
          selectedId: props["font"],
        };
      },
      setValueOnProps: (v: string, setPropertiesOnProps) =>
        setPropertiesOnProps({ font: v }),
      type: "select",
    },
    {
      label: "Font size",
      getValueFromProps: (props) => {
        return props["fontSize"];
      },
      setValueOnProps: (v: string, setPropertiesOnProps) =>
        setPropertiesOnProps({ fontSize: v }),
      type: "slider",
    },
    {
      label: "Text options",
      type: "multicheckbox",
      getValueFromProps: (props) => {
        return {
          items: styleItems,
          selectedIds: styleItems
            .map((i) => (props[i.id] ? i.id : undefined))
            .filter((v) => !!v),
        };
      },
      setValueOnProps: (selectedIds, setPropertiesOnProps) => {
        const props = styleItems
          .map((e) => e.id)
          .map((key) => {
            if (selectedIds.includes(key)) {
              return [key, true];
            } else {
              return [key, false];
            }
          });
        setPropertiesOnProps(Object.fromEntries(props));
      },
    },
    {
      label: "Letter by letter effect",
      getValueFromProps: (props) => props["withLetterByLetter"],
      setValueOnProps: (v: AnimatedLetterByLetter, setPropertiesOnProps) =>
        setPropertiesOnProps({ withLetterByLetter: v }),
      type: "checkbox",
    },
    {
      label: "Word by Word effect",
      getValueFromProps: (props) => props["withWordByWord"],
      setValueOnProps: (v: AnimatedWordByWord, setPropertiesOnProps) =>
        setPropertiesOnProps({ withWordByWord: v }),
      type: "checkbox",
    },
    {
      label: "Word by Word scale down",
      getValueFromProps: (props) => props["withWordByWordScaleDown"],
      setValueOnProps: (v: AnimatedWordByWord, setPropertiesOnProps) =>
        setPropertiesOnProps({ withWordByWordScaleDown: v }),
      type: "checkbox",
    },
    {
      label: "RGB effect",
      getValueFromProps: (props) => props["withRgb"],
      setValueOnProps: (v: boolean | undefined, setPropertiesOnProps) =>
        setPropertiesOnProps({ withRgb: v }),
      type: "checkbox",
    },
  ],
});
