import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  createVideoDefinitionStore,
  VideoDefinitionStore,
} from "../store/videoDefinitionStore";

const VideoDefinitionContext = createContext<VideoDefinitionStore | null>(null);

interface VideoDefinitionStoreProviderProps {
  children: ReactNode;
}

const useForceUpdate: () => () => void = () => {
  const [, updateState] = useState<Record<string, unknown>>();
  return useCallback(() => updateState({}), []);
};

export const useStore = () => {
  const store = useContext(VideoDefinitionContext);
  if (!store) {
    throw Error("Missing store");
  }
  const { globalObservers } = store;
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    globalObservers.register(forceUpdate);
    return () => {
      globalObservers.unregister(forceUpdate);
    };
  }, [forceUpdate, globalObservers]);

  return store;
};

export const VideoDefinitionStoreProvider = ({
  children,
}: VideoDefinitionStoreProviderProps) => {
  const store = createVideoDefinitionStore();
  return (
    <VideoDefinitionContext.Provider value={store}>
      {children}
    </VideoDefinitionContext.Provider>
  );
};
