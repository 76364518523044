import styled from "styled-components";
import { IllustrationContainer } from "./common";
import FrameLayout from "./FrameLayout";
import { registerSpotSequence } from "./spotSequences";

const IllustrationE = styled.div`
  width: 20px;
  height: 20px;
  background: #d8d8d8;
  border-radius: 4px;
`;

registerSpotSequence({
  key: "horizontal",

  getDisplay: ({ sequence, timing, zIndex }) => (
    <FrameLayout frame={sequence} zIndex={zIndex} timing={timing} />
  ),
  getIllustration: ({ onBackgroundClick, onElementClick, spotKeys }) => (
    <IllustrationContainer onClick={onBackgroundClick}>
      {spotKeys.map((k) => (
        <IllustrationE onClick={(e) => onElementClick(k, e)} />
      ))}
    </IllustrationContainer>
  ),
});
