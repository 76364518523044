type Observer<T> = (data?: T) => void;

export interface GlobalObserver<T> {
  register: (observer: Observer<T>) => void;
  unregister: (observer: Observer<T>) => void;
  notify: (data?: T) => void;
}

const createGlobalObserver = <T>(): GlobalObserver<T> => {
  const observers = new Set<Observer<T>>();

  return {
    register: (observer) => observers.add(observer),
    unregister: (observer) => observers.delete(observer),
    notify: (data?: T) => observers.forEach((o) => o(data)),
  };
};

export default createGlobalObserver;
