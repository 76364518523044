import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import {
  $getSelection,
  $isNodeSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_LOW,
  createCommand,
  LexicalCommand,
} from "lexical";
import { useEffect } from "react";
import { getSelectedNode } from "./utils/getSelectedNode";

export const ANIMATION_APPLY_COMMAND: LexicalCommand<void> = createCommand(
  "ANIMATION_APPLY_COMMAND"
);
export const ANIMATION_COPY_COMMAND: LexicalCommand<void> = createCommand(
  "ANIMATION_COPY_COMMAND"
);
let objectIdClipboard: string | undefined = undefined;

export const getAnimationClipboard = () => objectIdClipboard;
export const setAnimationClipboard = (id: string) => {
  objectIdClipboard = id;
};

interface CopyAnimationPluginProps {
  onAnimationApply: (objectIdFrom: string, objectIdTo: string) => void;
}
export const CopyAnimationPlugin = ({
  onAnimationApply,
}: CopyAnimationPluginProps) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        ANIMATION_COPY_COMMAND,
        () => {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection);
            setAnimationClipboard(node.__objectData?.id);
          } else if ($isNodeSelection(selection)) {
            const node = selection.getNodes()[0];
            setAnimationClipboard(node.__objectData?.id);
          }
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        ANIMATION_APPLY_COMMAND,
        () => {
          const selection = $getSelection();
          const clipboard = getAnimationClipboard();
          if (!clipboard) {
            return false;
          }
          if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection);
            onAnimationApply(clipboard, node.__objectData?.id);
          } else if ($isNodeSelection(selection)) {
            const node = selection.getNodes()[0];
            onAnimationApply(clipboard, node.__objectData?.id);
          }
          return false;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor]);

  return <></>;
};
