type propertyAnimation = [string, (x: number) => unknown];

export const CSS_PROPERTIES = [
  { id: "y", label: "y" },
  { id: "x", label: "x" },
  { id: "scale", label: "scale" },
  { id: "opacity", label: "opacity" },
  { id: "width", label: "width" },
];

export const getCombinedCssProperties = (properties: propertyAnimation[]) => {
  const transformProperties = properties.filter(([p]) =>
    p.startsWith("transform-")
  );
  const otherProperties = properties.filter(
    ([p]) => !p.startsWith("transform-")
  );

  if (transformProperties.length > 0) {
    const transformXProperty =
      transformProperties.find(([p]) => p === "transform-x")?.[1] ?? (() => 0);
    const transformYProperty =
      transformProperties.find(([p]) => p === "transform-y")?.[1] ?? (() => 0);

    const transformScaleProperty =
      transformProperties.find(([p]) => p === "transform-scale")?.[1] ??
      (() => 1);
    const transformRotateProperty =
      transformProperties.find(([p]) => p === "transform-rotate")?.[1] ??
      (() => 0);
    const rotate3dXProperty =
      transformProperties.find(([p]) => p === "transform-rotate3d-x")?.[1] ??
      (() => 0);
    const rotate3dYProperty =
      transformProperties.find(([p]) => p === "transform-rotate3d-y")?.[1] ??
      (() => 0);
    const rotate3dZProperty =
      transformProperties.find(([p]) => p === "transform-rotate3d-z")?.[1] ??
      (() => 0);
    const rotate3dAProperty =
      transformProperties.find(([p]) => p === "transform-rotate3d-a")?.[1] ??
      (() => 0);

    const transformProperty: [string, (x: number) => unknown] = [
      "transform",
      (x: number) =>
        `translate(${transformXProperty(x)}px,${transformYProperty(
          x
        )}px) scale(${transformScaleProperty(
          x
        )}) rotate(${transformRotateProperty(
          x
        )}deg)  rotate3d(${rotate3dXProperty(x)}, ${rotate3dYProperty(
          x
        )}, ${rotate3dZProperty(x)}, ${rotate3dAProperty(x)}deg)`,
    ];
    return [...otherProperties, transformProperty];
  }

  return [...otherProperties];
};

export const getCssProperty = (
  propertyName: string,
  twinFunction: (x: number) => number
): propertyAnimation => {
  if (propertyName === "scale") {
    return ["transform-scale", (x: number) => twinFunction(x)];
  } else if (propertyName === "x") {
    return ["transform-x", (x: number) => twinFunction(x)];
  } else if (propertyName === "y") {
    return ["transform-y", (x: number) => twinFunction(x)];
  } else if (propertyName === "rotate") {
    return ["transform-rotate", (x: number) => twinFunction(x)];
  } else if (propertyName === "filter-blur") {
    return ["filter", (x: number) => `blur(${twinFunction(x)}px)`];
  }
  return [propertyName, (x: number) => twinFunction(x)];
};
