import { Separator } from "../@/components/ui/separator";
import { ObjectTypes } from "../store/storeUtils";
import type { FrameTimeEditorProps } from "./FrameTimeEditor";
import FrameTimeEditor from "./FrameTimeEditor";
import SequenceEditor from "./SequenceEditor";
import { useStore } from "./VideoDefinitionStoreProvider";

export type SelectionType = "sequence" | "object" | "spot";

interface FrameEditorProps {
  selectionId: string;
  currentFrame: FrameTimeEditorProps["currentFrame"];
}

const FrameEditor = ({ selectionId, currentFrame }: FrameEditorProps) => {
  const store = useStore();
  const selection = store.getObject(selectionId);

  if (selection && selection.type === ObjectTypes.object) {
    return (
      <>
        <div className="space-y-1">
          <h4 className="text-sm font-medium leading-none">
            Frame {currentFrame.order}
          </h4>
        </div>
        <Separator className="my-4" />

        {currentFrame && <FrameTimeEditor currentFrame={currentFrame} />}
        {currentFrame && (
          <SequenceEditor
            key={currentFrame.object.id}
            sequenceId={currentFrame.object.id}
            sequence={currentFrame.object.data as any}
            onSequenceChange={(v) => {
              store.updateObject(currentFrame.object.id as string, {
                ...currentFrame.object.data,
                data: v,
              });
            }}
          ></SequenceEditor>
        )}
      </>
    );
  }
  return null;
};

export default FrameEditor;
