import { Input } from "../@/components/ui/input";
interface NumberInput {
  value: number;
  onChange: (n: number) => void;
  disabled?: boolean;
}
const NumberInput = ({ value, onChange, disabled }: NumberInput) => {
  return (
    <Input
      disabled={disabled}
      type="number"
      value={value}
      onChange={(e) => {
        onChange(Number(e.target.value));
      }}
    ></Input>
  );
};

export default NumberInput;
