import { createContext, ReactNode, useContext, useState } from "react";

const THEME_STORAGE_KEY = "obviouus-theme";

export const ThemeContext = createContext<{
  theme: "" | "dark";
  setTheme: (theme: "" | "dark") => void;
}>({
  theme: "",
  setTheme: () => {},
});

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const initialTheme =
    (localStorage.getItem(THEME_STORAGE_KEY) as "" | "dark" | undefined) ?? "";
  const [theme, setThemeState] = useState<"" | "dark">(initialTheme);

  const setTheme = (theme: "" | "dark") => {
    localStorage.setItem(THEME_STORAGE_KEY, theme);
    setThemeState(theme);
  };
  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
export const useTheme = () => {
  return useContext(ThemeContext);
};
