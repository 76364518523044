import Flex from "./Flex";
import Icon, { IconName } from "./icons";

const Loader = () => {
  return (
    <Flex>
      <>
        <div className="mr-2 animate-spin">
          <Icon name={IconName.wait} size={20}></Icon>
        </div>
      </>
      Loading...
    </Flex>
  );
};

export default Loader;
