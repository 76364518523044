import { Input } from "../@/components/ui/input";
import { Label } from "../@/components/ui/label";
import { FormGrid } from "../components/FormGrid";
import { useStore } from "./VideoDefinitionStoreProvider";

export interface FrameTimeEditorProps {
  currentFrame: {
    startTime: number;
    order: number;
    object: { id: string; data: { duration: number } };
  };
}
const FrameTimeEditor = ({ currentFrame }: FrameTimeEditorProps) => {
  const store = useStore();
  const elements = [
    <>
      <Label htmlFor="start">Start time (ms)</Label>
      <Input
        disabled
        id="start"
        placeholder="In milliseconds"
        type="number"
        value={currentFrame.startTime}
      />
    </>,
    <>
      <Label htmlFor="duration">Duration (ms)</Label>
      <Input
        id="duration"
        placeholder="In milliseconds"
        type="number"
        value={currentFrame.object.data.duration}
        onChange={(e) => {
          const object = store.getObject(currentFrame.object.id);
          store.updateObject(currentFrame.object.id, {
            ...object,
            data: {
              ...object.data,
              duration: parseFloat(e.target.value) || 1,
            },
          });
        }}
      />
    </>,
  ];
  return (
    <>
      <FormGrid elements={elements} />
    </>
  );
};

export default FrameTimeEditor;
