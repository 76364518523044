import type { PreviewListItem } from "./PreviewList";
import PreviewList from "./PreviewList";

export type PreviewListCheckboxItem = Omit<PreviewListItem, "isSelected">;
interface PreviewListCheckboxProps {
  items: PreviewListCheckboxItem[];
  selectedIds: string[];
  onAdd: (id: string) => void;
  onRemove: (id: string) => void;
}

const PreviewListCheckbox = ({
  items,
  selectedIds = [],
  onAdd,
  onRemove,
}: PreviewListCheckboxProps) => {
  const multiItems = items.map((i) => {
    return {
      ...i,
      isSelected: selectedIds.includes(i.id),
    };
  });
  const onToggleItem = (id: string) => {
    if (selectedIds.find((item) => item === id)) {
      onRemove(id);
    } else {
      onAdd(id);
    }
  };
  return (
    <PreviewList
      isHorizontal
      items={multiItems}
      onItemClick={onToggleItem}
    ></PreviewList>
  );
};

export default PreviewListCheckbox;
