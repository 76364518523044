import { Easing } from "../../utils/animationUtils";
import { registerTemplate } from "./templates";
registerTemplate({
  key: "textPattern1",
  title: "Text pattern",
  getFrames: () => {
    const duration = 1000;
    return [
      {
        duration: duration,
        background: "linear-gradient(135deg, #00DBDE 0%, #FC00FF 100%)",
        elements: [
          [
            [
              {
                duration,
                objects: [
                  {
                    type: "text",
                    props: {
                      text: "Amazing",
                      font: "Hikou-Outline",
                      fontSize: 45,
                      italic: true,
                      color: "#fff",
                    },
                    animations: {
                      custom: [
                        {
                          property: "scale",
                          timings: [
                            {
                              time: 0,
                              value: 1.3,
                              easing: Easing.easeOutCubic,
                            },
                            { time: duration / 2, value: 1 },
                          ],
                        },
                        {
                          property: "x",
                          timings: [
                            { time: 0, value: -30 },
                            { time: duration, value: -40 },
                          ],
                        },
                      ],
                    },
                  },
                ],
              },
            ],
          ],
          [
            [
              {
                duration,
                objects: [
                  {
                    type: "text",
                    props: {
                      text: "Amazing",
                      font: "Hikou-Outline",
                      fontSize: 45,
                      italic: true,
                      color: "#fff",
                    },
                    animations: {
                      custom: [
                        {
                          property: "scale",
                          timings: [
                            {
                              time: 0,
                              value: 1.3,
                              easing: Easing.easeOutCubic,
                            },
                            { time: duration / 2, value: 1 },
                          ],
                        },
                        {
                          property: "x",
                          timings: [
                            {
                              time: 0,
                              value: -25,
                              easing: Easing.easeOutCubic,
                            },
                            { time: duration, value: 30 },
                          ],
                        },
                      ],
                    },
                  },
                ],
              },
            ],
          ],
          [
            [
              {
                duration,
                objects: [
                  {
                    type: "text",
                    props: {
                      text: "Amazing",
                      font: "Hikou",
                      fontSize: 45,
                      italic: true,
                      color: "#fff",
                    },
                    animations: {
                      custom: [
                        {
                          property: "scale",
                          timings: [
                            {
                              time: 0,
                              value: 1.3,
                              easing: Easing.easeOutCubic,
                            },
                            { time: duration / 2, value: 1 },
                          ],
                        },
                        {
                          property: "x",
                          timings: [
                            { time: 0, value: -25 },
                            { time: duration, value: -30 },
                          ],
                        },
                      ],
                    },
                  },
                ],
              },
            ],
          ],
          [
            [
              {
                duration,
                objects: [
                  {
                    type: "text",
                    props: {
                      text: "Amazing",
                      font: "Hikou-Outline",
                      fontSize: 45,
                      italic: true,
                      color: "#fff",
                    },
                    animations: {
                      custom: [
                        {
                          property: "scale",
                          timings: [
                            {
                              time: 0,
                              value: 1.3,
                              easing: Easing.easeOutCubic,
                            },
                            { time: duration / 2, value: 1 },
                          ],
                        },
                        {
                          property: "x",
                          timings: [
                            {
                              time: 0,
                              value: -25,
                              easing: Easing.easeOutCubic,
                            },
                            { time: duration, value: 20 },
                          ],
                        },
                      ],
                    },
                  },
                ],
              },
            ],
          ],
        ],
      },
    ];
  },
});
