import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { MarkNode } from "@lexical/mark";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { ParagraphNode, TextNode } from "lexical";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import ComponentPickerPlugin from "./lexical-plugins/ComponentPickerPlugin";
import EmojiPickerPlugin from "./lexical-plugins/EmojiPickerPlugin";
import ImagePlugin from "./lexical-plugins/ImagePlugin";
import MarkdownShortcutPlugin from "./lexical-plugins/MarkdownShortcutPlugin";
import { EmojiNode } from "./lexical-plugins/nodes/EmojiNode";
import { ImageNode } from "./lexical-plugins/nodes/ImageNode";
import { RestoreFromStoragePlugin } from "./lexical-plugins/RestoreFromStoragePlugin";

import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { useStore } from "../editors/VideoDefinitionStoreProvider";
import { ObjectTypes } from "../store/storeUtils";
import CodeHighlightPlugin from "./lexical-plugins/CodeHighlightPlugin";
import type { ComponentPickerMenuProps } from "./lexical-plugins/ComponentPickerPlugin";
import { CopyAnimationPlugin } from "./lexical-plugins/CopyAnimationPlugin";
import DraggableBlockPlugin from "./lexical-plugins/DraggableBlockPlugin";
import { FloatingTextToolbarPlugin } from "./lexical-plugins/FloatingTextToolbar";
import { CustomParagraphNode } from "./lexical-plugins/nodes/CustomParagraphNode";
import { CustomTextNode } from "./lexical-plugins/nodes/CustomTextNode";
import { TableNode } from "./lexical-plugins/nodes/TableNode";
import { SelectionPlugin } from "./lexical-plugins/NodeSelectionPlugin";
import ShortcutPlugin from "./lexical-plugins/ShortcutPlugin";
import { TableContext, TablePlugin } from "./lexical-plugins/TablePlugin";
import PlaygroundEditorTheme from "./lexical-plugins/themes/PlaygroundEditorTheme";

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: any) {
  console.error(error);
}

interface EditorProps {
  value: string;
  onChange: (newValue: string) => void;
  onInsertTemplate: ComponentPickerMenuProps["onInsertTemplate"];
  onSelectionChange?: (newValue: string | undefined) => void;
  onAnimationApply: (objectIdFrom: string, objectIdTo: string) => void;
}
export const Editor = ({
  value,
  onChange,
  onSelectionChange,
  onAnimationApply,
  onInsertTemplate,
}: EditorProps) => {
  const store = useStore();
  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);
  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const initialConfig = {
    namespace: "Playground",
    nodes: [
      CodeHighlightNode,
      CodeNode,
      MarkNode,
      HeadingNode,
      QuoteNode,
      EmojiNode,
      ImageNode,
      TableNode,
      CustomParagraphNode,
      CustomTextNode,
      {
        replace: ParagraphNode,
        with: (node: any) => {
          return new CustomParagraphNode(node.key, { id: uuidv4() });
        },
      },
      {
        replace: TextNode,
        with: (node: any) => {
          return new CustomTextNode(
            node.__text ?? "",
            { id: uuidv4() },
            node.key
          );
        },
      },
    ],
    theme: {
      ...PlaygroundEditorTheme,
      store,
      onSelectionChange,
    },
    onError,
  };

  const cellEditorConfig = {
    namespace: "Playground",
    onError,
    nodes: [
      CustomTextNode,
      {
        replace: TextNode,
        with: (node: any) => {
          return new CustomTextNode(
            node.__text ?? "",
            { id: uuidv4() },
            node.key
          );
        },
      },
    ],
    theme: PlaygroundEditorTheme,
  };

  const storeData = (payload: string) => {
    return store.createObject({
      type: ObjectTypes.file,
      value: payload,
    });
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <TableContext>
        <>
          <RestoreFromStoragePlugin
            value={value}
            onChange={onChange}
            onSelectionChange={onSelectionChange}
          />
          <ComponentPickerPlugin onInsertTemplate={onInsertTemplate} />
          <SelectionPlugin />
          <TablePlugin cellEditorConfig={cellEditorConfig}>
            <AutoFocusPlugin />
            <PlainTextPlugin
              contentEditable={
                <ContentEditable className="TableNode__contentEditable" />
              }
              placeholder={null}
              ErrorBoundary={LexicalErrorBoundary}
            />
          </TablePlugin>
          <EmojiPickerPlugin />
          <CopyAnimationPlugin onAnimationApply={onAnimationApply} />
          <AutoFocusPlugin />
          <FloatingTextToolbarPlugin />
          <MarkdownShortcutPlugin />
          <CodeHighlightPlugin />
          <ImagePlugin storeData={storeData} />
          <ShortcutPlugin />
          {floatingAnchorElem ? (
            <>
              <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
            </>
          ) : (
            <></>
          )}
          <PlainTextPlugin
            contentEditable={
              <div className="editor-scroller" ref={onRef}>
                <ContentEditable />
              </div>
            }
            placeholder={<div></div>}
            ErrorBoundary={LexicalErrorBoundary}
          />
        </>
      </TableContext>
    </LexicalComposer>
  );
};
