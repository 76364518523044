import type { VideoDefinitionStore } from "../store/videoDefinitionStore";
import { store2VideoDefinition } from "../transformers/store2VideoDefinition";
import { EVENT_NAME, trackEvent } from "./tracking";

export const exportVideoLambda = async (
  store: VideoDefinitionStore,
  onDone?: () => void
) => {
  const videoDefinition = store2VideoDefinition({ store });
  trackEvent(EVENT_NAME.ATEMPTED_EXPORT, { videoDefinition });
  const response = await fetch("/api/lambda_export", {
    method: "POST",
    body: JSON.stringify({ videoDefinition }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (response.ok) {
    const result = await response.json();
    if (result?.data?.renderId) {
      getLambdaResult(result.data.renderId, onDone);
    }
  }
  return null;
};

export const getLambdaResult = async (
  renderId: string,
  onDone?: () => void
) => {
  let maxAttempt = 20;

  const tryFetch = async (renderId: string) => {
    maxAttempt = maxAttempt - 1;
    if (maxAttempt === 0) {
      return;
    }
    const response = await fetch("/api/lambda_result/" + renderId, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const blob = await response.blob();
    if (blob.type === "application/json") {
      setTimeout(() => tryFetch(renderId), 3000);
    } else {
      // Create a URL for the blob
      const blobUrl = URL.createObjectURL(blob);
      // Create a temporary anchor element for downloading
      const anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.download = "video.mp4"; // Set the desired filename
      onDone?.();
      // Simulate a click on the anchor to trigger the download
      document.body.appendChild(anchor);
      anchor.click();
    }
  };

  if (renderId) {
    setTimeout(() => tryFetch(renderId), 3000);
  }
};

export const exportVideo = async (store: VideoDefinitionStore) => {
  const videoDefinition = store2VideoDefinition({ store });
  trackEvent(EVENT_NAME.ATEMPTED_EXPORT, { videoDefinition });
  const response = await fetch("/api/export", {
    method: "POST",
    body: JSON.stringify({ videoDefinition }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const blob = await response.blob();
  // Create a URL for the blob
  const blobUrl = URL.createObjectURL(blob);
  // Create a temporary anchor element for downloading
  const anchor = document.createElement("a");
  anchor.href = blobUrl;
  anchor.download = "video.mp4"; // Set the desired filename
  // Simulate a click on the anchor to trigger the download
  document.body.appendChild(anchor);
  anchor.click();
  return true;
};

export const exportVideoDefer = async (
  store: VideoDefinitionStore,
  onDone?: () => void
) => {
  const videoDefinition = store2VideoDefinition({ store });

  const response = await fetch("/api/defer_export", {
    method: "POST",
    body: JSON.stringify({ videoDefinition }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  let maxAttempt = 20;
  const { data } = await response.json();

  const tryFetch = async (executionId: string) => {
    maxAttempt = maxAttempt - 1;
    if (maxAttempt === 0) {
      return;
    }
    const response = await fetch("/api/result/" + executionId, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const blob = await response.blob();
    if (blob.type === "application/json") {
      setTimeout(() => tryFetch(executionId), 2000);
    } else {
      // Create a URL for the blob
      const blobUrl = URL.createObjectURL(blob);
      // Create a temporary anchor element for downloading
      const anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.download = "video.mp4"; // Set the desired filename
      onDone?.();
      // Simulate a click on the anchor to trigger the download
      document.body.appendChild(anchor);
      anchor.click();
    }
  };

  if (data.executionID) {
    setTimeout(() => tryFetch(data.executionID), 2000);
  }
};
