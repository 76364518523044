import { useRef } from "react";
import styled from "styled-components";
import { Input } from "../@/components/ui/input";
import { Label } from "../@/components/ui/label";
import { Popover } from "../components/Popover";
import { sizes } from "../components/sizes";
import { theme } from "../components/theme";

const PickerContainer = styled.div`
  position: absolute;
  z-index: 4;
`;

const ColorPicker = styled.div`
  cursor: pointer;
  display: flex;
  width: fit-content;
  border: 1px solid hsl(var(--background));
  border-radius: 5px;
  &:hover {
    border: 1px solid white;
    border-color: hsl(var(--border));
  }
`;

const size = 24;
const ColorDisplay = styled.div`
  cursor: pointer;
  height: ${size}px;
  width: ${size}px;
  border-radius: 5px;
  margin: ${sizes.padding.small}px;
  border: 1px solid ${theme.colors.border};
  border-color: hsl(var(--input));
`;
const ColorDisplayMain = styled.div`
  cursor: pointer;
  height: ${size * 1.2}px;
  width: ${size * 1.2}px;
  border-radius: 5px;
  margin: ${sizes.padding.small}px;
  border: 1px solid ${theme.colors.border};
  border-color: hsl(var(--input));
`;
const ColorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

interface ColorEditorProps {
  value: string;
  onChange: (c: string) => void;
}

const ColorEditor = ({ value, onChange }: ColorEditorProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [hex, ...letters] = (value ?? "#fff").split("");
  const effectiveValue = letters.join("");
  const palette = ["#eebefa", "#bac8ff", "#b2f2bb", "#ffec99"];
  return (
    <ColorContainer>
      {palette.map((c) => (
        <ColorPicker key={c} onClick={() => onChange(c)}>
          <ColorDisplay style={{ background: c }}></ColorDisplay>
        </ColorPicker>
      ))}

      <Popover
        trigger={
          <ColorPicker>
            <ColorDisplayMain style={{ background: value }}></ColorDisplayMain>
          </ColorPicker>
        }
      >
        <div className="grid gap-4">
          <div className="grid grid-cols-3 items-center gap-4">
            <Label htmlFor="width">Hex</Label>
            <Input
              prefix="#"
              value={effectiveValue}
              onChange={(e) => {
                onChange("#" + e.target.value);
              }}
              id="width"
              defaultValue="100%"
              className="col-span-2 h-8"
            />
          </div>
        </div>
      </Popover>
    </ColorContainer>
  );
};

export default ColorEditor;
