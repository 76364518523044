import type { ReactNode } from "react";
import styled from "styled-components";

interface FormGridProps {
  elements: ReactNode[];
}

const ColumnWrapper = styled.div`
  grid-auto-cols: minmax(100px, auto);
  grid-template-columns: 0.5fr 2fr;
  padding-right: 16px;
`;
export const FormGrid = ({ elements }: FormGridProps) => {
  return (
    <div className="grid gap-2">
      {elements.map((e) => (
        <ColumnWrapper className="grid items-center gap-4">{e}</ColumnWrapper>
      ))}
    </div>
  );
};
