import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import type { VideoDefinition } from "three-modules";
import { ReplaceWarningDialog } from "../app-components/ReplaceWarningDialog";
import Loader from "../components/Loader";

const STORAGE_KEY = "videoDefinition";
export const VideoDefinitionStorageContext = createContext<{
  videoDefinition: VideoDefinition | null;
  setVideoDefinition: (v: VideoDefinition) => void;
}>({ videoDefinition: null, setVideoDefinition: () => {} });

interface VideoDefinitionStorageProviderProps {
  children: ReactNode;
}

const getVideoDefinitionFromText = () => {
  const videoDefinition: VideoDefinition = {
    frames: [
      {
        background:
          "linear-gradient( 174.2deg,  rgba(255,244,228,1) 7.1%, rgba(240,246,238,1) 67.4% )",
        duration: 1000,
        elements: [
          [
            [
              {
                objects: [
                  {
                    type: "text",
                    props: {
                      text: "This is your video content",
                      fontSize: 28,
                      font: "Poppins-Regular",
                      uppercase: false,
                      color: "#000",
                      italic: false,
                      underline: false,
                      code: false,
                    },
                    animations: {},
                  },
                ],
              },
            ],
          ],
        ],
      },
      {
        background:
          "linear-gradient( 174.2deg,  rgba(255,244,228,1) 7.1%, rgba(240,246,238,1) 67.4% )",
        duration: 1000,
        elements: [
          [
            [
              {
                objects: [
                  {
                    type: "text",
                    props: {
                      text: "Proceed to the editor tab to edit and animate",
                      fontSize: 28,
                      font: "Poppins-Regular",
                      uppercase: false,
                      color: "#000",
                      italic: false,
                      underline: false,
                      code: false,
                    },
                    animations: {},
                  },
                ],
              },
            ],
          ],
        ],
      },
    ],
  };
  return videoDefinition;
};

export const VideoDefinitionStorageProvider = ({
  children,
}: VideoDefinitionStorageProviderProps) => {
  const [showWarning, setShowWarning] = useState(false);
  const [isDataLoading, setDataLoading] = useState(false);
  const videoDefinitionString = localStorage.getItem(STORAGE_KEY);
  let [searchParams] = useSearchParams();
  const demo = searchParams.get("demo");
  const [videoDefinition, setVideoDefinition] =
    useState<VideoDefinition | null>(null);

  const loadDemo = async (demo: string) => {
    setDataLoading(true);
    const response = await fetch(`/api/demo/${demo}`);
    const { data } = (await response.json()) as {
      data: VideoDefinition | null;
    };
    if (data) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
      document.location.search = "";
      document.location = "video";
    }
    setDataLoading(false);
  };

  const onReplace = () => {
    if (demo) {
      loadDemo(demo);
    }
  };

  const onCancel = () => {
    document.location.search = "";
  };

  useEffect(() => {
    if (demo) {
      if (videoDefinitionString) {
        setShowWarning(true);
      } else {
        loadDemo(demo);
      }
    } else {
      if (videoDefinitionString) {
        setVideoDefinition(JSON.parse(videoDefinitionString));
      } else {
        const generatedVideo = getVideoDefinitionFromText();
        setVideoDefinition(generatedVideo);
      }
    }
  }, [videoDefinitionString]);

  return (
    <>
      {showWarning && (
        <ReplaceWarningDialog
          onOpenChange={setShowWarning}
          onReplace={onReplace}
          onCancel={onCancel}
          isLoading={isDataLoading}
        />
      )}
      {isDataLoading && <Loader />}
      {!isDataLoading && (
        <VideoDefinitionStorageContext.Provider
          value={{ videoDefinition, setVideoDefinition }}
        >
          {children}
        </VideoDefinitionStorageContext.Provider>
      )}
    </>
  );
};

export const useSaveVideoDefinition = () => {
  const { setVideoDefinition } = useContext(VideoDefinitionStorageContext);
  const saveVideoDefinition = (videoDefinition: VideoDefinition) => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(videoDefinition));
  };
  return { saveVideoDefinition, setVideoDefinition };
};
