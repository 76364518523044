import { ReactNode, useMemo } from "react";
import { useVideoConfig } from "remotion";
import type { AnimatedWordByWord } from "../../../types/types";
import { Easing } from "../../../utils/animationUtils";
import { AnimatedObject } from "../base/AnimatedObject";

interface WordByWordScaleDownProps {
  text: string;
  props?: AnimatedWordByWord;
  children: (text: string) => ReactNode;
}

const WordByWordScaleDown = ({
  children,
  text,
  props,
}: WordByWordScaleDownProps) => {
  const { fontSize = 50, timings } = props ?? {};
  const { durationInFrames, fps } = useVideoConfig();
  const durationInMs = (durationInFrames / fps) * 1000;

  const startScale = 2;
  const words = text.split(" ");
  const getTextLength = (text: string) =>
    text.split("").length * (fontSize / 2);
  const durations = useMemo(() => words.map(() => 400), []);
  durations[0] = 0;

  const getStartOfIndex = (index: number) => {
    return durations.reduce((acc, current, currIndex) => {
      if (currIndex <= index) {
        return acc + current;
      }
      return acc + 0;
    }, 0);
  };
  const getRemainingText = (index: number) => {
    return words.filter((e, i) => i > index).join(" ");
  };

  const scaleAnimation = [
    {
      property: "scale",
      timings: durations.flatMap((_, i) => {
        const from = getStartOfIndex(i);
        return [
          {
            time: from,
            value: startScale - (i * (startScale - 1)) / words.length,
            easing: Easing.easeOutQuad,
          },
          {
            time: from + 200,
            value: startScale - ((i + 1) * (startScale - 1)) / words.length,
            easing: Easing.easeOutQuad,
          },
        ];
      }),
    },
  ];
  const animationList = useMemo(
    () =>
      words.map((w, index) => {
        const currentFrom = getStartOfIndex(index);
        const nextFrom =
          index === words.length - 1
            ? durationInMs
            : getStartOfIndex(index + 1);
        const remainingText = words.filter((e, i) => i > index).join(" ");
        return [
          {
            property: "opacity",
            timings: [
              { time: 0, value: 0 },
              ...(currentFrom > 0 ? [{ time: currentFrom, value: 0 }] : []),
              { time: currentFrom, value: 1 },
            ],
          },
          {
            property: "x",
            timings: durations.flatMap((_, i) => {
              const from = getStartOfIndex(i);
              const fromNext = getStartOfIndex(i + 1);
              const remainingText = getRemainingText(i);
              const remainingText2 = getRemainingText(i + 1);

              return [
                {
                  time: from,
                  value: getTextLength(remainingText) / 2,
                },
                {
                  time: fromNext,
                  value: getTextLength(remainingText2) / 2,
                },
              ];
            }),
          },
        ];
      }),
    []
  );
  return (
    <AnimatedObject animations={scaleAnimation}>
      <div
        style={{
          display: "flex",
          height: "fit-content",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: fontSize / 4,
          padding: 8,
        }}
      >
        {words.map((word, index) => (
          <AnimatedObject key={word + index} animations={animationList[index]}>
            {children(word)}
          </AnimatedObject>
        ))}
      </div>
    </AnimatedObject>
  );
};

export default WordByWordScaleDown;
