import { ThemeContext, ThemeProvider } from "../editors/ThemeProvider";
import {
  VideoDefinitionStorageContext,
  VideoDefinitionStorageProvider,
} from "../editors/VideoDefinitionStorageProvider";
import { VideoDefinitionStoreProvider } from "../editors/VideoDefinitionStoreProvider";
import { VideoEditor } from "../editors/VideoEditor";

const AppRoot = () => {
  return (
    <VideoDefinitionStorageProvider>
      <VideoDefinitionStoreProvider>
        <VideoDefinitionStorageContext.Consumer>
          {({ videoDefinition }) => {
            return videoDefinition ? (
              <ThemeProvider>
                <ThemeContext.Consumer>
                  {({ theme }) => (
                    <div className={theme ?? ""}>
                      <div className="App">
                        <VideoEditor
                          videoDefinition={videoDefinition}
                        ></VideoEditor>
                      </div>
                    </div>
                  )}
                </ThemeContext.Consumer>
              </ThemeProvider>
            ) : null;
          }}
        </VideoDefinitionStorageContext.Consumer>
      </VideoDefinitionStoreProvider>
    </VideoDefinitionStorageProvider>
  );
};

export default AppRoot;
