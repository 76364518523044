import styled from "styled-components";
import type { VFrame } from "three-modules";
import { Separator } from "../@/components/ui/separator";
import { getFrameStartTime } from "../transformers/store2VideoDefinition";
import BackgroundEditor from "./BackgroundEditor";
import { useStore } from "./VideoDefinitionStoreProvider";

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface SequenceEditorProps {
  sequenceId: string;
  sequence: VFrame;
  onSequenceChange: (s: VFrame) => void;
}

export const SequenceEditor: React.FC<SequenceEditorProps> = ({
  sequence,
  sequenceId,
  onSequenceChange,
}) => {
  const store = useStore();
  const { from, index } = getFrameStartTime(store, sequenceId);

  const onPropertyChange = (propKey: string, value: unknown) => {
    const newSequence = {
      ...sequence,
      [propKey]: value,
    } as VFrame;
    onSequenceChange(newSequence);
  };

  return (
    <div>
      <FlexContainer>
        <>
          <Separator className="my-4" />

          <div className="space-y-1 pb-4">
            <h4 className="text-sm font-medium leading-none">Background</h4>
            <p className="text-sm text-muted-foreground">
              Change frame background
            </p>
          </div>
          <BackgroundEditor
            value={sequence.background ?? "transparent"}
            onChange={(a) => {
              onSequenceChange({
                ...sequence,
                background: a,
              });
            }}
          ></BackgroundEditor>
        </>
      </FlexContainer>
    </div>
  );
};

export default SequenceEditor;
