import type { FileRecord, VFrame } from "../types";

export interface TemplateDefinition {
  key: string;
  title: string;
  getFrames: () => VFrame[];
  getFiles?: () => FileRecord;
}

const TEMPLATES: Record<string, TemplateDefinition> = {};

export const registerTemplate = (templateDefinition: TemplateDefinition) => {
  TEMPLATES[templateDefinition.key] = templateDefinition;
};

export const getTemplateHandler = (key: string) => {
  const def = TEMPLATES[key];
  if (!def) {
    return undefined;
  }
  return def;
};

export const getTemplates = () => {
  return TEMPLATES;
};
