import type { ReactNode } from "react";
import {
  Popover as PopoverBase,
  PopoverContent,
  PopoverTrigger,
} from "../@/components/ui/popover";

interface PopoverComponentProps {
  children: ReactNode;
  trigger: ReactNode;
}
export const Popover = ({ children, trigger }: PopoverComponentProps) => {
  return (
    <PopoverBase>
      <PopoverTrigger asChild>{trigger}</PopoverTrigger>
      <PopoverContent className="w-80">{children}</PopoverContent>
    </PopoverBase>
  );
};
