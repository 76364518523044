import { Player } from "@remotion/player";
import styled from "styled-components";
import {
  AnimatedObjectDisplayer,
  PropertyAnimation,
  Text,
  TextProps,
} from "three-modules";

interface TextStylePreviewProps {
  textProps: Omit<TextProps, "text">;
}
interface AnimatedTextProps {
  textProps: Omit<TextProps, "text">;
  animations: PropertyAnimation[];
}

const size = 33;
const PreviewContainer = styled.div`
  width: ${3 * size}px;
  height: ${size}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: hsl(var(--muted));
  border-radius: 5px;
  overflow: hidden;
`;

const TextStylePreview = ({ textProps }: TextStylePreviewProps) => (
  <PreviewContainer>
    <Text {...textProps} text="Sample" color="hsl(var(--foreground))" />
  </PreviewContainer>
);

const AnimatedText = ({ textProps, animations }: AnimatedTextProps) => {
  const object = {
    type: "text",
    props: { ...textProps, text: "Sample", color: "hsl(var(--foreground))" },
    animations: { custom: animations },
  };
  return (
    <PreviewContainer>
      <AnimatedObjectDisplayer object={object} />
    </PreviewContainer>
  );
};

export const TextStyleAnimationPreview = ({
  textProps,
  animations,
}: AnimatedTextProps) => {
  return (
    <Player
      fps={50}
      inputProps={{ textProps, animations }}
      durationInFrames={180}
      compositionWidth={size * 3}
      compositionHeight={size}
      autoPlay
      loop
      component={AnimatedText}
    ></Player>
  );
};

export default TextStylePreview;
