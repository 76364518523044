import * as React from "react";
import styled from "styled-components";

import { cn } from "../../lib/utils";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  prefix?: string;
}

const InputPrefix = styled.span`
  color: hsl(var(--muted-foreground));
  margin-right: 8px;
`;
const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, prefix, ...props }, ref) => {
    return (
      <div
        className={cn(
          "flex h-9 w-full rounded-md border border-input bg-background px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
      >
        {prefix && <InputPrefix>{prefix}</InputPrefix>}
        <input
          className={cn(
            "w-full bg-background file:border-0 file:bg-transparent file:text-sm file:font-medium"
          )}
          type={type}
          ref={ref}
          {...props}
        />
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
