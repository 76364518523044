import {
  $applyNodeReplacement,
  EditorConfig,
  ParagraphNode,
  SerializedParagraphNode,
} from "lexical";

import "./CustomParagraphNode.css";

import type { Spread } from "lexical";

export type SerializedCustomParagraphNode = Spread<
  {
    frameData: FrameData;
  },
  SerializedParagraphNode
>;

export const $createCustomParagraphNode = ({
  key,
  frameData,
}: {
  key: string | undefined;
  frameData: FrameData;
}): CustomParagraphNode => {
  return $applyNodeReplacement(new CustomParagraphNode(key, frameData));
};

interface FrameData {
  id: string;
}

export class CustomParagraphNode extends ParagraphNode {
  __frameData: FrameData;
  static getType(): string {
    return "custom-paragraph";
  }

  constructor(key: string | undefined, frameData: FrameData) {
    super(key);
    this.__frameData = frameData;
  }

  static clone(node: CustomParagraphNode): CustomParagraphNode {
    return new CustomParagraphNode(node.__key, node.__frameData);
  }

  createDOM(config: EditorConfig): HTMLElement {
    // const parent = document.createElement("div");
    // parent.classList.add("FrameContainer");
    const dom = super.createDOM(config);
    dom.classList.add("FrameContainer");
    // parent.appendChild(dom);
    // return parent;
    return dom;
  }

  updateDOM() {
    return true;
  }

  exportJSON(): SerializedCustomParagraphNode {
    const a = super.exportJSON();
    return {
      ...a,
      type: "custom-paragraph",
      frameData: this.getFrameData(),
    };
  }

  static importJSON(
    serializedNode: SerializedCustomParagraphNode
  ): CustomParagraphNode {
    const { frameData } = serializedNode;
    const node = $createCustomParagraphNode({
      key: undefined,
      frameData,
    });
    node.setFormat(serializedNode.format);
    node.setIndent(serializedNode.indent);
    node.setDirection(serializedNode.direction);
    return node;
  }

  getFrameData() {
    return this.__frameData;
  }
}
