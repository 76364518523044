import {
  Select as SelectBase,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../@/components/ui/select";

interface SelectProps {
  value: string;
  onChange: (v: string) => void;
  placeholder?: string;
  label?: string;
  items: { id: string; label: string }[];
}
export const Select = ({
  placeholder,
  label,
  items,
  value,
  onChange,
}: SelectProps) => {
  return (
    <SelectBase onValueChange={onChange} value={value}>
      <SelectTrigger className="w-full">
        <SelectValue placeholder={placeholder ?? ""} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {label && <SelectLabel>{label}</SelectLabel>}
          {items.map((item) => (
            <SelectItem key={item.id} value={item.id}>
              {item.label}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </SelectBase>
  );
};
