import type { ReactNode } from "react";
import {
  Tooltip as TooltipBase,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../@/components/ui/tooltip";

interface TooltipProps {
  children: ReactNode;
  tooltip: string;
}

const Tooltip = ({ children, tooltip }: TooltipProps) => {
  return (
    <TooltipProvider delayDuration={200}>
      <TooltipBase>
        <TooltipTrigger>{children}</TooltipTrigger>
        {tooltip && (
          <TooltipContent side="left">
            <p>{tooltip}</p>
          </TooltipContent>
        )}
      </TooltipBase>
    </TooltipProvider>
  );
};

export default Tooltip;
