import type { ReactNode } from "react";
import useResizeObserver from "use-resize-observer";
import {
  preBuiltAnimations,
  PreBuiltAnimationType,
} from "../../../utils/prebuiltAnimations";
import type { TimingProps } from "../../../utils/timings";
import { AnimatedObject } from "./AnimatedObject";

interface ObjectAppearMaskProps {
  children: ReactNode;
  timing?: TimingProps;
  height?: number;
}

const ObjectAppearMask = ({ children, timing }: ObjectAppearMaskProps) => {
  const { ref, height } = useResizeObserver();
  const translateAnimation = preBuiltAnimations[PreBuiltAnimationType.enterUp].animation({
    timing,
    startY: height,
  });
  return (
    <div
      style={{
        height: "fit-content",
        overflow: "hidden",
      }}
    >
      <AnimatedObject ref={ref} animations={translateAnimation}>
        {children}
      </AnimatedObject>
    </div>
  );
};

export default ObjectAppearMask;
