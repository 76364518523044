import React from "react";
import styled from "styled-components";
import type { VSequence } from "../../types/types";
import { AnimatedObjectDisplayer } from "../AnimatedObjectDisplayer";

interface SpotProps {
  sequences: VSequence[];
}

const SpotContainer = styled.div`
  position: relative;
`;
const SpotChild = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;

const Spot = ({ sequences }: SpotProps) => {
  return sequences.length === 1 && sequences[0]?.objects.length == 1 ? (
    sequences[0]?.objects[0] ? (
      <AnimatedObjectDisplayer
        key={"oIndex"}
        object={sequences[0]?.objects[0]}
      ></AnimatedObjectDisplayer>
    ) : null
  ) : (
    <SpotContainer>
      {sequences.map((spotSequence, index) => {
        return spotSequence.objects?.map((object, oIndex) => (
          <React.Fragment key={index + "" + oIndex}>
            <SpotChild>
              <AnimatedObjectDisplayer
                key={oIndex}
                object={object}
              ></AnimatedObjectDisplayer>
            </SpotChild>
            <div style={{ height: 0 }}>
              <AnimatedObjectDisplayer
                key={oIndex}
                object={object}
              ></AnimatedObjectDisplayer>
            </div>
          </React.Fragment>
        ));
      })}
    </SpotContainer>
  );
};

export default Spot;
