import { Label } from "../@/components/ui/label";
import { Select } from "./Select";
const sounds = [
  {
    id: "podcastIntro",
    label: "The Podcast Intro",
  },
  {
    id: "bounce",
    label: "Bounce",
  },
  {
    id: "hiphop",
    label: "Hip Hop Rock Beats",
  },
];

interface SoundSelectorProps {
  value: string;
  onChange: (v: string) => void;
}
const SoundSelector = ({ value, onChange }: SoundSelectorProps) => {
  return (
    <div className="flex flex-col space-y-1.5">
      <Label>Sound</Label>
      <Select
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
        items={sounds}
      />
    </div>
  );
};

export default SoundSelector;
