import { EditorConfig, NodeKey, SerializedTextNode, TextNode } from "lexical";

import type { Spread } from "lexical";
import { v4 as uuidv4 } from "uuid";
import "./CustomTextNode.css";

export type SerializedCustomTextNode = Spread<
  {
    objectData: ObjectData;
  },
  SerializedTextNode
>;

export const $createCustomTextNode = ({
  objectData,
  text,
}: any): CustomTextNode => {
  return new CustomTextNode(text, objectData);
};

interface ObjectData {
  id: string;
}

export class CustomTextNode extends TextNode {
  __objectData: ObjectData;
  static getType(): string {
    return "custom-text";
  }

  constructor(text: string, objectData: ObjectData, key?: NodeKey) {
    // console.trace("constructor", text, objectData);
    super(text, key);
    this.__objectData = objectData ?? { id: uuidv4() };
  }

  static clone(node: CustomTextNode): CustomTextNode {
    const clone = new CustomTextNode(
      node.__text,
      node.__objectData,
      node.__key
    );
    return clone;
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    dom.className = "CustomTextNode";
    // const clickable = document.createElement("span");
    // clickable.className = "CustomTextNodeMenu";
    // dom.appendChild(clickable);
    return dom;
  }

  exportJSON(): SerializedCustomTextNode {
    const a = super.exportJSON();
    return {
      ...a,
      type: "custom-text",
      objectData: this.getObjectData(),
    };
  }

  isSimpleText() {
    return this.__type === "custom-text" && this.__mode === 0;
  }

  static importJSON(serializedNode: SerializedCustomTextNode): CustomTextNode {
    const { objectData, text } = serializedNode;
    const node = $createCustomTextNode({
      objectData,
      text,
    });
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  getObjectData() {
    return this.__objectData;
  }
}
