import type { ReactNode } from "react";
import type { AnimatedLetterByLetter } from "../../../types/types";
import ObjectAppear from "../base/ObjectAppearMask";

interface LetterByLetterWrapperProps {
  text: string;
  props: AnimatedLetterByLetter;
  children: (text: string) => ReactNode;
}

const LetterByLetterWrapper = ({
  text,
  props,
  children,
}: LetterByLetterWrapperProps) => {
  const { from = 0, speed = 1 } = props;
  const letters = text.split("");
  const durationOfAppearance = 50 / speed;
  const delayBetweenLetters = durationOfAppearance / 2;
  const effectiveTiming = letters.map((_, index) => ({
    from: delayBetweenLetters * index + from,
    duration: durationOfAppearance,
  }));
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      {letters.map((letter, index) => (
        <ObjectAppear timing={effectiveTiming[index]} key={letter + index}>
          {children(letter)}
        </ObjectAppear>
      ))}
    </div>
  );
};

export default LetterByLetterWrapper;
