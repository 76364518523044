import "../types/animatedObjects";
import "../types/spotSequences";
import { getSpotSequenceHandler } from "../types/spotSequences/spotSequences";
import "../types/templates";
import type { VideoDefinition } from "../types/types";
import Sound from "./components/Sound";
import { FilesContext } from "./FilesContext";
import "./index.css";

type VideoProps = {
  videoDefinition: VideoDefinition;
};
export const Video = ({ videoDefinition = { frames: [] } }: VideoProps) => {
  let from = 0;
  const renderer = getSpotSequenceHandler("horizontal")?.getDisplay;
  return (
    <FilesContext.Provider value={{ files: videoDefinition.files ?? {} }}>
      <div
        style={{
          backgroundColor: "black",
          height: "100%",
          display: "flex",
          flex: 1,
        }}
      >
        {videoDefinition.frames.map((frame, seqIndex) => {
          from = from + (videoDefinition.frames[seqIndex - 1]?.duration ?? 0);
          return (
            <span key={seqIndex}>
              {renderer &&
                renderer({
                  sequence: frame,
                  timing: {
                    from: from,
                    duration: frame.duration,
                  },
                  zIndex: videoDefinition.frames.length - seqIndex,
                })}
            </span>
          );
        })}
        {videoDefinition.sound && <Sound sound={videoDefinition.sound} />}
      </div>
    </FilesContext.Provider>
  );
};
