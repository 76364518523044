"use client";

import { Checkbox as CheckboxBase } from "../@/components/ui/checkbox";

interface CheckboxProps {
  value: boolean;
  onChange: (v: boolean) => void;
  label: string;
  subtitle?: string;
}

export const Checkbox = ({
  value,
  onChange,
  label,
  subtitle,
}: CheckboxProps) => {
  return (
    <div className="flex items-center space-x-2">
      <CheckboxBase id={label} checked={value} onCheckedChange={onChange} />
      {label && (
        <label
          htmlFor={label}
          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          {label}
        </label>
      )}
      {subtitle && <p className="text-sm text-muted-foreground">{subtitle}</p>}
    </div>
  );
};
