import { Easing } from "../../utils/animationUtils";
import {
  preBuiltAnimations,
  PreBuiltAnimationType,
} from "../../utils/prebuiltAnimations";
import { registerTemplate } from "./templates";
registerTemplate({
  key: "noMore",
  title: "No more",
  getFrames: () => {
    const duration = 2700;
    const fontSize = 50;
    return [
      {
        duration: duration,
        background: "linear-gradient(135deg, #00DBDE 0%, #FC00FF 100%)",
        elements: [
          [
            [
              {
                objects: [
                  {
                    type: "text",
                    props: {
                      text: "No more",
                      font: "Poppins-ExtraBold",
                      uppercase: true,
                      fontSize: fontSize,
                      color: "#fff",
                    },
                    animations: {
                      [PreBuiltAnimationType.enterScaleUp]: preBuiltAnimations[
                        PreBuiltAnimationType.enterScaleUp
                      ].animation({ easing: Easing.bounce }),
                    },
                  },
                ],
              },
            ],
            [
              {
                duration: 1000,
                objects: [
                  {
                    type: "text",
                    props: {
                      text: "Worries",
                      font: "Poppins-ExtraBold",
                      uppercase: true,
                      fontSize: fontSize,
                      color: "#fff",
                    },
                    animations: {
                      [PreBuiltAnimationType.shake]: preBuiltAnimations[
                        PreBuiltAnimationType.shake
                      ].animation({}),
                      custom: [
                        {
                          property: "x",
                          timings: [{ time: 0, value: 10 }],
                        },
                        {
                          property: "y",
                          timings: [
                            { time: 100, value: -300 },
                            { time: 400, value: -fontSize },
                            { time: 1400, value: -fontSize },
                            { time: 1700, value: 300 },
                          ],
                        },
                      ],
                    },
                  },
                ],
              },
              {
                duration: 1000,
                objects: [
                  {
                    type: "text",
                    props: {
                      text: "Issues",
                      font: "Poppins-ExtraBold",
                      uppercase: true,
                      fontSize: fontSize,
                      color: "#fff",
                    },
                    animations: {
                      [PreBuiltAnimationType.shake]: preBuiltAnimations[
                        PreBuiltAnimationType.shake
                      ].animation({}),
                      custom: [
                        {
                          property: "x",
                          timings: [{ time: 0, value: 10 }],
                        },
                        {
                          property: "y",
                          timings: [
                            { time: 1400, value: -300 },
                            { time: 1700, value: -fontSize },
                          ],
                        },
                      ],
                    },
                  },
                ],
              },
            ],
          ],
        ],
      },
    ];
  },
});
