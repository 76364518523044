import { createStore } from "./genericStore";

export type VideoDefinitionStore = ReturnType<
  typeof createVideoDefinitionStore
>;

export const createVideoDefinitionStore = () => {
  const {
    createObject,
    updateObject,
    deleteObject,
    getObject,
    getAll,
    clear,
    globalObservers,
  } = createStore();

  const byType = (type: string) => {
    return Array.from(getAll()).filter((values) => values.type === type);
  };


  // const createSequence = (sequenceData : SequenceData) => {

  // }

  return {
    createObject,
    updateObject,
    deleteObject,
    getObject,
    clear,
    byType,
    globalObservers,
  };
};
