import {
  getAnimatedObjectHandler,
  preBuiltAnimations,
  PreBuiltAnimationType,
} from "three-modules";
import { Badge } from "../@/components/ui/badge";
import { Separator } from "../@/components/ui/separator";
import AnimationPreview from "../app-components/AnimationPreview";
import { TextStyleAnimationPreview } from "../app-components/TextStyleReview";
import Align from "../components/Align";
import PreviewListCheckbox from "../components/PreviewListCheckbox";
import { ObjectTypes, StoreAnimatedObject } from "../store/storeUtils";
import ObjectAnimationsEditor from "./ObjectAnimationsEditor";
import { useStore } from "./VideoDefinitionStoreProvider";

export type SelectionType = "sequence" | "object" | "spot";

interface AnimationEditorProps {
  selectionId: string;
}

const onPropertiesChange =
  (
    object: StoreAnimatedObject,
    onObjectChange: (o: StoreAnimatedObject) => void
  ) =>
  (newProps: Record<string, unknown>) => {
    onObjectChange({
      ...object,
      props: { ...object.props, ...newProps },
    });
  };

const AnimationEditor = ({ selectionId }: AnimationEditorProps) => {
  const store = useStore();
  const selection = store.getObject(selectionId);

  const onObjectChange = (v: StoreAnimatedObject) => {
    store.updateObject(selection.id as string, {
      ...selection,
      data: v,
    });
  };

  if (selection && selection.type === ObjectTypes.object) {
    const object = selection.data;
    const objectHandler = getAnimatedObjectHandler(object.type);
    const builtInAnimationKeys = Object.keys(object.animations).filter(
      (e) => e !== "custom"
    );
    const selectedIds = builtInAnimationKeys ?? [];
    const commonAnimations = Object.values(PreBuiltAnimationType).filter(
      (animation) => !preBuiltAnimations[animation].types
    );
    const commonAnimationItems = commonAnimations.map((animation) => ({
      id: animation,
      label: preBuiltAnimations[animation].label,
      component: () => {
        return (
          <AnimationPreview
            animations={preBuiltAnimations[animation].animation({
              timing: { duration: 1000 },
            })}
          ></AnimationPreview>
        );
      },
    }));

    const typeAnimations = Object.values(PreBuiltAnimationType).filter(
      (animation) => preBuiltAnimations[animation].types?.includes(object.type)
    );
    const typeAnimationItems = typeAnimations.map((animation) => ({
      id: animation,
      label: preBuiltAnimations[animation].label,
      component: () => {
        if (object.type === "text") {
          return (
            <TextStyleAnimationPreview
              textProps={{
                font: "Poppins-Regular",
                uppercase: false,
                fontSize: 10,
              }}
              animations={preBuiltAnimations[animation].animation({})}
            ></TextStyleAnimationPreview>
          );
        } else {
          return <AnimationPreview animations={[]}></AnimationPreview>;
        }
      },
    }));
    return (
      <>
        <div className="space-y-1">
          <h4 className="text-sm font-medium leading-none">Animation</h4>
          <p className="text-sm text-muted-foreground">Use bultin animations</p>
        </div>
        <div>
          <PreviewListCheckbox
            items={commonAnimationItems}
            selectedIds={selectedIds}
            onRemove={(id: any) => {
              const animations = { ...object.animations };
              delete animations[id];
              onObjectChange({
                ...object,
                animations,
              });
            }}
            onAdd={(newId: any) => {
              onObjectChange({
                ...object,
                animations: {
                  ...(object.animations ?? {}),
                  [newId]: preBuiltAnimations[
                    newId as PreBuiltAnimationType
                  ].animation({}),
                },
              });
            }}
          ></PreviewListCheckbox>
        </div>
        {typeAnimationItems.length > 0 && (
          <>
            <Separator className="my-4" />
            <div className="space-y-1">
              <p className="text-sm text-muted-foreground">
                {objectHandler?.title} animations
              </p>
            </div>
            <div>
              <PreviewListCheckbox
                items={typeAnimationItems}
                selectedIds={selectedIds}
                onRemove={(id: any) => {
                  const animations = { ...object.animations };
                  delete animations[id];
                  onObjectChange({
                    ...object,
                    animations,
                  });
                }}
                onAdd={(newId: any) => {
                  onObjectChange({
                    ...object,
                    animations: {
                      ...(object.animations ?? {}),
                      [newId]: preBuiltAnimations[
                        newId as PreBuiltAnimationType
                      ].animation({}),
                    },
                  });
                }}
              ></PreviewListCheckbox>
            </div>
          </>
        )}
        <Separator className="my-4" />
        <div className="space-y-1 pb-4">
          <Align>
            <h4 className="text-sm font-medium leading-none">
              Customize Animation
            </h4>{" "}
            <Badge>Beta</Badge>
          </Align>
          <p className="text-sm text-muted-foreground">Fine tune animations.</p>
        </div>
        <ObjectAnimationsEditor
          key={selection.id + "-animation"}
          animationRecord={object.animations}
          onAnimationsChange={(animations) => {
            store.updateObject(selection.id as string, {
              ...selection,
              data: {
                ...selection.data,
                animations,
              },
            });
          }}
        />
      </>
    );
  }

  return null;
};

export default AnimationEditor;
