import { IconName } from "../components/icons";
import { Tabs } from "../components/Tabs";
import { ObjectTypes } from "../store/storeUtils";
import { useStore } from "./VideoDefinitionStoreProvider";

interface EditorMenuRefs {
  tab: string;
  selectionId?: string;
  onChange?: (key: string) => void;
}

const EditorMenu = ({ tab, onChange, selectionId }: EditorMenuRefs) => {
  const store = useStore();
  const selection = selectionId ? store.getObject(selectionId) : null;
  const object =
    selection && selection.type === ObjectTypes.object ? selection.data : null;
  const selectionIcon = object
    ? object?.type === "text"
      ? IconName.text
      : IconName.image
    : IconName.selection;

  const tabs = [
    {
      icon: selectionIcon ?? IconName.selection,
      iconTooltip: "Selection",
      key: "selection",
    },
    {
      icon: IconName.video,
      iconTooltip: "Animation",
      key: "animations",
      withSeparator: true,
    },
    {
      icon: IconName.frame,
      iconTooltip: "Frame",
      key: "frame",
    },
    {
      icon: IconName.sound,
      iconTooltip: "Sound",
      key: "sound",
    },
  ];

  return (
    <div>
      <Tabs tab={tab} isVertical tabs={tabs} onChange={onChange}></Tabs>
    </div>
  );
};
export default EditorMenu;
