import { Player } from "@remotion/player";
import styled from "styled-components";
import { getVideoDuration, TemplateDefinition, Video } from "three-modules";

interface TemplatePreviewProps {
  template: TemplateDefinition;
  onClick: () => void;
}

const width = 500;
const height = 300;
const PreviewContainer = styled.div`
  width: ${width / 3}px;
  height: ${height / 3}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: hsl(var(--muted));
  border-radius: 5px;
  overflow: hidden;
`;

const TemplatePreview = ({ template, onClick }: TemplatePreviewProps) => {
  const frames = template.getFrames();
  const files = template.getFiles?.();
  const fps = 30;
  const durationInMs = getVideoDuration({ frames });
  const durationInFrames = Math.floor((durationInMs * fps) / 1000);
  return (
    <PreviewContainer onClick={onClick}>
      <Player
        fps={fps}
        inputProps={{ videoDefinition: { frames, files: files ?? {} } }}
        durationInFrames={durationInFrames}
        compositionWidth={width}
        compositionHeight={height}
        autoPlay
        loop
        component={Video}
      ></Player>
    </PreviewContainer>
  );
};

export default TemplatePreview;
