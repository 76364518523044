import styled from "styled-components";

export const IllustrationContainer = styled.div`
  max-width: 60px;
  min-width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  border: 1px solid #d8d8d8;
  padding: 4px;
  border-radius: 4px;
  gap: 4px;
  justify-content: center;
`;
