import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import {
  $getSelection,
  $isNodeSelection,
  $isRangeSelection,
  EditorState,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import { useCallback, useEffect, useRef } from "react";
import { getSelectedNode } from "./utils/getSelectedNode";

interface RestoreFromStoragePluginProps {
  value?: string;
  onChange: (v: string) => void;
  onSelectionChange?: (v: string | undefined) => void;
}
export const RestoreFromStoragePlugin = ({
  value,
  onChange,
  onSelectionChange,
}: RestoreFromStoragePluginProps) => {
  const [editor] = useLexicalComposerContext();
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      if (value) {
        // console.log("Initing rich text from initial value...");
        const initialEditorState = editor.parseEditorState(value);
        if (!initialEditorState.isEmpty()) {
          editor.setEditorState(initialEditorState);
        }
      }
    }
  }, [value, editor]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        const selection = $getSelection();
        if (
          $isRangeSelection(selection) &&
          selection.anchor.key === selection.focus.key
        ) {
          const node = getSelectedNode(selection);
          onSelectionChange?.(node.__objectData?.id);
        } else if (
          $isNodeSelection(selection) &&
          selection &&
          selection.getNodes().length === 1
        ) {
          const node = selection.getNodes()[0];
          onSelectionChange?.(node.__objectData?.id);
        } else {
          onSelectionChange?.(undefined);
        }
        return false;
      },
      1
    );
  }, [editor]);

  const onChangeHandler = useCallback(
    (editorState: EditorState) => {
      onChange(JSON.stringify(editorState.toJSON()));
    },
    [onChange]
  );

  // TODO: add ignoreSelectionChange
  return (
    <OnChangePlugin
      onChange={onChangeHandler}
      ignoreSelectionChange={true}
      ignoreHistoryMergeTagChange={true}
    />
  );
};
