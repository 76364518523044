import type { IconListMenuItem } from "./IconListMenu";
import IconListMenu from "./IconListMenu";

export type IconListCheckboxItem = Omit<IconListMenuItem, "isSelected">;
interface IconListCheckboxProps {
  items: IconListCheckboxItem[];
  selectedIds: string[];
  onChange: (ids: string[]) => void;
}

const IconListCheckbox = ({
  items,
  selectedIds = [],
  onChange,
}: IconListCheckboxProps) => {
  const multiItems = items.map((i) => {
    return {
      ...i,
      isSelected: selectedIds.includes(i.id),
    };
  });
  const onToggleItem = (id: string) => {
    if (selectedIds.find((item) => item === id)) {
      onChange(selectedIds.filter((item) => item !== id));
    } else {
      onChange([...selectedIds, id]);
    }
  };
  return (
    <IconListMenu items={multiItems} onItemClick={onToggleItem}></IconListMenu>
  );
};

export default IconListCheckbox;
