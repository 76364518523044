import { useVideoConfig } from "remotion";
import { useAnimationStyle } from "../../utils/useAnimationStyle";

const TransitionMask = ({ clipId }: { clipId: string }) => {
  const { width, height } = useVideoConfig();
  const animation1 = useAnimationStyle({
    animations: [
      {
        property: "x",
        timings: [
          { time: 0, value: 0 },
          { time: 990, value: -3000 },
        ],
      },
    ],
  });
  const animation2 = useAnimationStyle({
    animations: [
      {
        property: "x",
        timings: [
          { time: 0, value: 0 },
          { time: 990, value: 3000 },
        ],
      },
    ],
  });

  return (
    <svg width={width} height={height}>
      <defs>
        <clipPath id={clipId}>
          <path
            d={`M 0 0 L ${width / 2 - 300} 2 L ${
              width / 2 + 300
            } ${height} L 0 ${height}`}
            fill="red"
            style={{ ...animation1 }}
          />
          <path
            d={`M ${width} 0 L ${width / 2 - 300} 2 L ${
              width / 2 + 300
            } ${height} L ${width} ${height}`}
            fill="red"
            style={{ ...animation2 }}
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TransitionMask;
