import type { PlayerRef } from "@remotion/player";
import { useEffect, useRef, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Markdown from "react-markdown";

import {
  getTemplateHandler,
  getVideoDuration,
  VFrame,
  VideoDefinition,
} from "three-modules";
import { Button } from "../@/components/ui/button";
import Icon, { IconName } from "../components/icons";
import { Editor } from "../components/Lexical";
import SoundSelector from "../components/SoundSelector";
import TopHeader from "../pages/TopHeader";
import { getObjectFrameId, ObjectTypes } from "../store/storeUtils";
import { richText2Store } from "../transformers/richText2Store";
import {
  getFrameStartTime,
  store2VideoDefinition,
} from "../transformers/store2VideoDefinition";
import { videoDefinition2RichText } from "../transformers/videoDefinition2RichText";
import {
  overrideVFrameInStore,
  videoDefinition2Store,
} from "../transformers/videoDefinition2Store";
import { exportVideoLambda } from "../utils/videoExport";
import AnimationEditor from "./AnimationEditor";
import EditorMenu from "./EditorMenu";
import FrameEditor from "./FrameEditor";
import SelectionEditor from "./SelectionEditor";
import { VideoControlProvider } from "./VideoControlProvider";
import { useSaveVideoDefinition } from "./VideoDefinitionStorageProvider";
import { useStore } from "./VideoDefinitionStoreProvider";
import { VideoPlayer } from "./VideoPlayer";

const LayoutWrapper = styled.div`
  /* grid-template-rows: 1fr auto; */
  height: 100%;
  overflow-y: auto;
`;
const ElementEditorContainer = styled.div`
  width: 100%;
  padding: 4px;
`;

const ConfigEditor = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
`;

const EditorContainer = styled.div`
  position: relative;
  padding-bottom: 440px;
  padding-left: 8px;
`;
const GridContainer = styled.div`
  display: grid;
  height: calc(100vh - 60px);
  overflow-y: hidden;
`;

const EmptyState = () => {
  return (
    <div className="space-y-1 pb-4 flex justify-center">
      <p className="text-sm text-muted-foreground">
        <b>Select an element</b> on the left panel
      </p>
    </div>
  );
};

interface VideoEditorProps {
  videoDefinition: VideoDefinition;
}

const StyledButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 8px;
  margin: 16px;
`;

export const VideoEditor: React.FC<VideoEditorProps> = ({
  videoDefinition,
}) => {
  const navigate = useNavigate();

  const { tab: urlTab } = useParams<{ tab?: string }>();
  const tab = urlTab ?? "script";
  const onAnimationApply = (idObjectFrom: string, idObjectTo: string) => {
    const animations = store.getObject(idObjectFrom).data.animations;
    //TODO add wordByWord animation
    //TODO add letterByLetter animation

    const o = store.getObject(idObjectTo);
    store.updateObject(idObjectTo, {
      ...o,
      data: {
        ...o.data,
        props: {
          ...o.data.props,
        },
        animations,
      },
    });
  };
  const onJsonChange = (newValue: string) => {
    richText2Store({ store, jsonValue: newValue });
  };

  const store = useStore();

  const [isDownloading, setIsDwnloading] = useState(false);
  const [menuEditorTab, setMenuEditorTab] = useState("selection");
  const { saveVideoDefinition, setVideoDefinition } = useSaveVideoDefinition();
  const hasInited = useRef(false);
  const [selectionId, setSelectionId] = useState<string | undefined>();
  const [currentFrameId, setCurrentFrameId] = useState<string>();
  const playerRef = useRef<PlayerRef>(null);

  const onInsertTemplate = (template: string, currentFramerId: string) => {
    const templateHandler = getTemplateHandler(template);
    const frames = templateHandler?.getFrames() ?? ([{}] as VFrame[]);
    const files = templateHandler?.getFiles?.() ?? {};
    overrideVFrameInStore({ store, frame: frames[0], files, currentFramerId });
    const newFrames = hasInited.current
      ? store2VideoDefinition({ store, withId: true })
      : { frames: [] };
    const jsonValue = videoDefinition2RichText(newFrames);
    const jsonValueString = JSON.stringify(jsonValue);
    return jsonValueString;
  };

  const onSelectionChange = (newValue: string | undefined) => {
    setSelectionId(newValue);
  };

  useEffect(() => {
    const object = selectionId ? store.getObject(selectionId) : undefined;
    if (object && selectionId) {
      if (object.type === ObjectTypes.frame) {
        const { from } = getFrameStartTime(store, selectionId);
        const frame = (from / 1000) * fps;
        playerRef.current?.seekTo(frame);
      } else if (object.type === ObjectTypes.object) {
        const frameId = getObjectFrameId(store, selectionId);
        const { from } = getFrameStartTime(store, frameId);
        const frame = (from / 1000) * fps;
        playerRef.current?.seekTo(frame);
        setCurrentFrameId(frameId);
      }
    }
  }, [selectionId, tab]);

  let currentFrame = null;
  if (currentFrameId && store.getObject(currentFrameId)) {
    const { from, index } = getFrameStartTime(store, currentFrameId);
    currentFrame = {
      object: store.getObject(currentFrameId),
      startTime: from,
      order: index,
    } as const;
  }

  useEffect(() => {
    if (!hasInited.current) {
      // console.log("Initiiiing store ....");
      videoDefinition2Store({ store, videoDefinition });
      hasInited.current = true;
    }
  }, [videoDefinition]);
  const newDefinition = hasInited.current
    ? store2VideoDefinition({ store, withId: true })
    : { frames: [] };
  if (hasInited.current) {
    saveVideoDefinition(store2VideoDefinition({ store }));
  }

  const jsonValue = videoDefinition2RichText(newDefinition);
  const jsonValueString = JSON.stringify(jsonValue);

  const fps = 30;
  const durationInMs = getVideoDuration(newDefinition);
  const durationInFrames = Math.floor((durationInMs * fps) / 1000);

  // console.log("video", {
  //   newDefinition,
  //   durationInMs,
  //   jsonValue,
  // });
  const onLayout = (id: string) => (sizes: number[]) => {
    sessionStorage.setItem(
      `resizable-panels-${id}:layout`,
      JSON.stringify(sizes)
    );
  };
  const getLayout = (id: string) =>
    JSON.parse(
      sessionStorage.getItem(`resizable-panels-${id}:layout`) ?? "[33,67]"
    );
  const tabs = [
    {
      label: "Script",
      key: "script",
    },
    {
      label: "Editor",
      key: "editor",
    },
    {
      label: "Video",
      key: "video",
    },
  ];

  const markdown = `
  ### Effective Date: 2024/06/06

  AdsPerformance ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share information about you when you use our app.
  
  ## **Information We Collect**:
  
  User Data: We collect data from your Facebook profile, including your name, email address, and profile picture, when you install our app.
  Ad Data: We access your Facebook Ads data, including campaigns, ad sets, ads, spend, reach, and impressions.
  How We Use Your Information:
  
  To display your ads data as requested.
  Information Sharing:
  We do not share your personal information with third parties except to comply with legal obligations.
  
  ## **Data Security**:
  We implement appropriate security measures to protect your data from unauthorized access.
  
  ## **Changes to This Policy**:
  We may update this Privacy Policy. Any changes will be posted on this page with an updated effective date.
  
  ## **Contact Us**:
  If you have any questions, please contact us at obviouus.app+privacy@gmail.com.
  `;

  return (
    <VideoControlProvider fps={fps} playerRefCurrent={playerRef?.current}>
      <TopHeader
        tab={tab}
        durationInMs={durationInMs}
        onSet={(data) => {
          hasInited.current = false;
          sessionStorage.setItem("videoDefinition", JSON.stringify(data));
          setVideoDefinition(data);
        }}
        tabs={tabs}
        onTabChange={(newTab) => navigate("/" + newTab)}
      />
      <GridContainer>
        {(tab === "editor" || tab === "script") && (
          <PanelGroup
            direction="horizontal"
            onLayout={tab === "script" ? undefined : onLayout("editor")}
          >
            <Panel
              defaultSize={
                tab === "script" ? 100 : getLayout("editor")[0] ?? 67
              }
            >
              <LayoutWrapper>
                <EditorContainer>
                  {hasInited.current && (
                    <Editor
                      value={jsonValueString}
                      onChange={onJsonChange}
                      onAnimationApply={onAnimationApply}
                      onSelectionChange={onSelectionChange}
                      onInsertTemplate={onInsertTemplate}
                    ></Editor>
                  )}
                </EditorContainer>
              </LayoutWrapper>
            </Panel>
            {tab === "editor" && (
              <>
                <PanelResizeHandle
                  className="w-2"
                  style={{
                    borderLeft: "1px solid hsl(var(--border))",
                  }}
                />
                <Panel defaultSize={getLayout("editor")[1] ?? 33}>
                  <ConfigEditor>
                    {newDefinition?.frames.length > 0 && (
                      <PanelGroup direction="vertical">
                        <Panel defaultSize={33}>
                          <VideoPlayer
                            playerRef={playerRef}
                            fps={fps}
                            durationInFrames={durationInFrames}
                            videoDefinition={newDefinition}
                          ></VideoPlayer>
                        </Panel>
                        <PanelResizeHandle
                          className="h-2"
                          style={{
                            marginTop: 8,
                            borderTop: "1px solid hsl(var(--border))",
                          }}
                        />
                        <Panel defaultSize={67} style={{ overflow: "scroll" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            className="pb-4"
                          >
                            <ElementEditorContainer>
                              {menuEditorTab === "selection" &&
                                (selectionId ? (
                                  <SelectionEditor selectionId={selectionId} />
                                ) : (
                                  <EmptyState />
                                ))}
                              {menuEditorTab === "animations" &&
                                (selectionId ? (
                                  <AnimationEditor selectionId={selectionId} />
                                ) : (
                                  <EmptyState />
                                ))}
                              {menuEditorTab === "frame" &&
                                (currentFrame && selectionId ? (
                                  <FrameEditor
                                    currentFrame={currentFrame}
                                    selectionId={selectionId}
                                  />
                                ) : (
                                  <EmptyState />
                                ))}
                              {menuEditorTab === "sound" && (
                                <SoundSelector
                                  value={
                                    store.getObject("sound")?.value ?? {
                                      soundType: "id",
                                      value: "podcastIntro",
                                    }
                                  }
                                  onChange={(v) => {
                                    store.updateObject("sound", {
                                      soundType: "id",
                                      value: v,
                                    });
                                  }}
                                />
                              )}
                            </ElementEditorContainer>
                            <EditorMenu
                              tab={menuEditorTab}
                              onChange={setMenuEditorTab}
                              selectionId={selectionId}
                            ></EditorMenu>
                          </div>
                        </Panel>
                      </PanelGroup>
                    )}
                  </ConfigEditor>
                </Panel>
              </>
            )}
          </PanelGroup>
        )}
        {tab === "video" && (
          <LayoutWrapper style={{ padding: "40px" }}>
            <div style={{ height: 400 }}>
              {newDefinition?.frames.length > 0 && (
                <VideoPlayer
                  playerRef={playerRef}
                  fps={fps}
                  durationInFrames={durationInFrames}
                  videoDefinition={newDefinition}
                ></VideoPlayer>
              )}
              <StyledButtonContainer>
                <Button
                  variant="outline"
                  onClick={async () => {
                    navigate("/editor");
                  }}
                >
                  Edit video
                </Button>
                <Button
                  onClick={async () => {
                    setIsDwnloading(true);
                    // await exportVideoDefer(store, () => setIsDwnloading(false));
                    await exportVideoLambda(store, () =>
                      setIsDwnloading(false)
                    );
                  }}
                  disabled={isDownloading}
                >
                  {isDownloading && (
                    <>
                      <div className="mr-2 animate-spin">
                        <Icon name={IconName.wait} size={20}></Icon>
                      </div>
                      Please wait
                    </>
                  )}
                  {!isDownloading && "Download video"}
                </Button>
              </StyledButtonContainer>
            </div>
          </LayoutWrapper>
        )}
        {tab === "privacy_policy" && (
          <LayoutWrapper style={{ padding: "40px" }}>
            <div style={{ height: 400 }}>
              <Markdown>{markdown}</Markdown>
            </div>
          </LayoutWrapper>
        )}
      </GridContainer>
    </VideoControlProvider>
  );
};

export default VideoEditor;
