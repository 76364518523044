import type { ReactNode } from "react";
import { useVideoConfig } from "remotion";
import type { AnimatedWordByWord } from "../../../types/types";
import { Easing } from "../../../utils/animationUtils";
import { useAnimationStyle } from "../../../utils/useAnimationStyle";
import ObjectAppear from "../base/ObjectAppearMask";

interface WordByWordWrapperProps {
  text: string;
  props?: AnimatedWordByWord;
  children: (text: string) => ReactNode;
}

const WordByWordWrapper = ({
  children,
  text,
  props,
}: WordByWordWrapperProps) => {
  const { fontSize = 50, withGapAnimation, timings } = props ?? {};
  const { durationInFrames, width, fps } = useVideoConfig();
  const durationInMs = (durationInFrames / fps) * 1000;

  const words = text.split(" ");

  const appearDuration = Math.min(1000, durationInMs);
  const fromCoef = (appearDuration * (3 / 4)) / words.length;
  const effectiveTiming =
    timings ??
    words.map((_, index) => ({
      from: fromCoef * index,
      duration: appearDuration - fromCoef * (words.length - 1),
    }));

  const gapAnimation = useAnimationStyle({
    animations: [
      {
        property: "gap",
        timings: [
          {
            time: appearDuration - 100,
            value: fontSize / 2,
            easing: Easing.easeOutQuad,
          },
          {
            time: appearDuration,
            value: fontSize / 4,
          },
        ],
      },
    ],
  });

  return (
    <div
      style={{
        display: "flex",
        height: "fit-content",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: withGapAnimation ? undefined : fontSize / 4,
        padding: 8,
        ...(withGapAnimation ? gapAnimation : {}),
      }}
    >
      {words.map((word, index) => (
        <ObjectAppear timing={effectiveTiming[index]} key={word + index}>
          {children(word)}
        </ObjectAppear>
      ))}
    </div>
  );
};

export default WordByWordWrapper;
