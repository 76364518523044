import styled from "styled-components";
import { Label } from "../@/components/ui/label";
import Align from "../components/Align";
import { FormGrid } from "../components/FormGrid";
import { sizes } from "../components/sizes";
import { theme } from "../components/theme";
import ColorEditor from "./ColorEditor";

const BgPicker = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid gray;
`;
const ColorPicker = styled.div`
  cursor: pointer;
  display: flex;
  width: fit-content;
  border: 1px solid hsl(var(--background));
  border-radius: 5px;
  &:hover {
    border: 1px solid white;
    border-color: hsl(var(--border));
  }
`;

const size = 24;
const ColorDisplay = styled.div`
  cursor: pointer;
  height: ${size}px;
  width: ${size}px;
  border-radius: 5px;
  margin: ${sizes.padding.small}px;
  border: 1px solid ${theme.colors.border};
  border-color: hsl(var(--input));
`;

interface BackgroundEditorProps {
  value: string;
  onChange: (c: string) => void;
}
const BackgroundEditor = ({ value, onChange }: BackgroundEditorProps) => {
  const backgrounds = [
    "radial-gradient( circle farthest-corner at 10% 20%,  rgba(176,229,208,1) 42%, rgba(92,202,238,0.41) 93.6% )",
    "linear-gradient(225deg, #74EBD5 0%, #9FACE6 66%, #d8d2ff 100%)",
    "linear-gradient(225deg, #FBE6E9 0%, #EAF7FE 66%, #FFFEFE 100%)",
    "radial-gradient( circle 311px at 8.6% 27.9%,  rgba(62,147,252,0.57) 12.9%, rgba(239,183,192,0.44) 91.2% )",
    "linear-gradient(to right, #f857a6, #ff5858)",
    "linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%)",
    "linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%)",
    "linear-gradient(55deg, #d3d3d3 40%, #e1cb97 100%)",
    "linear-gradient( 174.2deg,  rgba(255,244,228,1) 7.1%, rgba(240,246,238,1) 67.4% )",
  ];
  const elements = [
    <>
      <Label>Color</Label>
      <ColorEditor value={value} onChange={onChange}></ColorEditor>
    </>,
    <>
      <Label>Gradients</Label>
      <Align>
        {backgrounds.map((bg, index) => (
          <ColorPicker key={bg} onClick={() => onChange(bg)}>
            <ColorDisplay style={{ background: bg }}></ColorDisplay>
          </ColorPicker>
        ))}
      </Align>
    </>,
  ];
  return (
    <>
      <FormGrid elements={elements} />
    </>
  );
};

export default BackgroundEditor;
