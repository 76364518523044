import {
  ObjectTypes,
  SOUND,
  STARTID,
  StoreFrame,
  StoreSequence,
} from "../store/storeUtils";
import type { VideoDefinitionStore } from "../store/videoDefinitionStore";

export const getObjectsForSequence = (
  store: VideoDefinitionStore,
  sequenceId: string,
  withId: boolean
) => {
  return store
    .byType(ObjectTypes.object)
    .filter((value) => value.sequenceId === sequenceId)
    .map((o) => ({
      ...(withId ? { id: o.id } : {}),
      ...o.data,
    }));
};

const getElementsFromSequences = (
  store: VideoDefinitionStore,
  sequences: any[], //OSequence[],
  withId: boolean
) => {
  const matrix: StoreSequence[][][] = [];
  sequences.forEach((sequence) => {
    const [line, column] = sequence.position;
    if (!matrix[line]) {
      matrix[line] = [];
    }
    if (!matrix[line][column]) {
      matrix[line][column] = [];
    }
    matrix[line][column].push({ ...sequence.data, id: sequence.id });
  });
  return matrix.map((row) => {
    return row.map((subSequences) => {
      return subSequences
        .filter((column) => {
          const objects = getObjectsForSequence(store, column.id, withId);
          return objects.length > 0;
        })
        .map((column) => ({
          id: column.id,
          objects: getObjectsForSequence(store, column.id, withId),
        }));
    });
  });
};

interface ConvertStoreToDefinitionProps {
  store: VideoDefinitionStore;
  withId?: boolean;
}

const getFrameElements = (
  store: VideoDefinitionStore,
  frame: StoreFrame,
  withId: boolean
) => {
  const sequences = store
    .byType(ObjectTypes.sequence)
    .filter((value) => value.frameId === frame.id);
  return getElementsFromSequences(store, sequences, withId);
};

export const getFrameStartTime = (store: VideoDefinitionStore, id: string) => {
  let from = 0;
  let index = 1;
  let frameId = store.getObject(STARTID)?.startId;
  let frame = store.getObject(frameId);
  while (frame && frame.id !== id) {
    const { duration, nextSequenceId, ...data } = frame.data;
    from = from + duration;
    frame = nextSequenceId ? store.getObject(nextSequenceId) : undefined;
    index = index + 1;
  }
  return { from, index };
};

export const getFrames = (
  store: VideoDefinitionStore,
  withId: boolean = false
) => {
  let frameId = store.getObject(STARTID)?.startId;
  let frame = store.getObject(frameId);
  let frames: StoreFrame[] = [];
  while (frame) {
    const { duration, nextSequenceId, ...data } = frame.data;
    const result = {
      ...data,
      duration,
      ...(withId ? { id: frame.id } : {}),
      elements: getFrameElements(store, frame, withId),
    };
    frames = [...frames, result];
    frame = nextSequenceId ? store.getObject(nextSequenceId) : undefined;
  }
  return frames;
};

export const store2VideoDefinition = ({
  store,
  withId,
}: ConvertStoreToDefinitionProps) => {
  return {
    frames: getFrames(store, withId),
    sound: store.getObject(SOUND),
    files: Object.fromEntries(
      store.byType(ObjectTypes.file).map((f) => [f.id, f]) //TODO Do not save files that are not used
    ),
  };
};
