import type { PreBuiltAnimationType } from "../utils/prebuiltAnimations";
import type { TimelineTimingProps, TimingProps } from "../utils/timings";

export type Transition = "openCurtain" | "fadeOut";

export type FileRecord = Record<string, { value: string; id: string }>;
export type AnimationRecord = Partial<
  Record<PreBuiltAnimationType | "custom", PropertyAnimation[]>
>;
export interface PropertyAnimation {
  property: string;
  timings: TimelineTimingProps[];
}

export interface VAnimatedObject {
  type: string;
  animations: AnimationRecord;
  props: Record<string, unknown>;
}

export interface AnimatedWordByWord {
  fontSize?: number;
  timings?: TimingProps[];
  withGapAnimation?: boolean;
}

export interface AnimatedLetterByLetter {
  from?: number;
  speed?: number;
}

export interface VFrame {
  duration: number;
  background?: string;
  backgroundImage?: string;
  endTransition?: Transition;
  elements: VSequence[][][];
}

export interface VSequence {
  duration?: number;
  objects: VAnimatedObject[];
}
export interface SoundProps {
  soundType: "url" | "id";
  value: string;
}
export interface VideoDefinition {
  frames: VFrame[];
  sound?: SoundProps;
  files?: FileRecord;
}

export const getVideoDuration = (v: VideoDefinition) => {
  return v.frames.reduce((acc, curr) => {
    return acc + curr.duration;
  }, 0);
};
