import ImageAnimation, {
  ImageProps,
} from "../../remotion/components/ImageAnimation";
import { registerAnimatedObject } from "./animatedObjects";

registerAnimatedObject<ImageProps>({
  key: "image",
  title: "Image",
  getDisplay: (props) => <ImageAnimation props={props}></ImageAnimation>,
  getEditors: () => [
    {
      label: "Url",
      getValueFromProps: (props) =>
        props.imageType === "url" ? props.value : "",
      setValueOnProps: (v, setPropertiesOnProps) =>
        setPropertiesOnProps({ value: v, imageType: "url" }),
      type: "text",
    },
    {
      label: "File",
      getValueFromProps: (props) =>
        props.imageType === "id" ? props.value : "",
      setValueOnProps: (v, setPropertiesOnProps) =>
        setPropertiesOnProps({ value: v, imageType: "id" }),
      type: "file",
    },
    {
      label: "Width",
      getValueFromProps: (props) => props.width,
      setValueOnProps: (v, setPropertiesOnProps) =>
        setPropertiesOnProps({ width: v }),
      type: "number",
    },
    {
      label: "Height",
      getValueFromProps: (props) => props.height,
      setValueOnProps: (v, setPropertiesOnProps) =>
        setPropertiesOnProps({ height: v }),
      type: "number",
    },
    {
      label: "Round border",
      getValueFromProps: (props) => props.borderRound,
      setValueOnProps: (v, setPropertiesOnProps) =>
        setPropertiesOnProps({ borderRound: v }),
      type: "checkbox",
    },
    {
      label: "Zoom animation",
      getValueFromProps: (props) => props.zoomAnimation,
      setValueOnProps: (v, setPropertiesOnProps) =>
        setPropertiesOnProps({ zoomAnimation: v }),
      type: "checkbox",
    },
    {
      label: "Shadow",
      getValueFromProps: (props) => props.withShadow,
      setValueOnProps: (v, setPropertiesOnProps) =>
        setPropertiesOnProps({ withShadow: v }),
      type: "checkbox",
    },
  ],
});
