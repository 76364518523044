import styled from "styled-components";
import Align from "./Align";
import type { IconName } from "./icons";
import Icon from "./icons";
import { theme } from "./theme";
import { Toggle } from "./Toggle";

interface IconListMenuProps {
  items: IconListMenuItem[];
  onItemClick: (id: string) => void;
}
export interface IconListMenuItem {
  id: string;
  icon: IconName;
  isSelected?: boolean;
}

const StyledIconListMenu = styled.div`
  display: flex;
  gap: 4px;
  padding: 3px;
  width: fit-content;
  border: 1px solid ${theme.colors.border};
  border-color: hsl(var(--input));
  border-radius: calc(var(--radius) - 2px);
`;

const IconListMenu = ({ items, onItemClick }: IconListMenuProps) => {
  return (
    <StyledIconListMenu>
      {items.map((item) => {
        return (
          <Align key={item.id}>
            <Toggle
              value={Boolean(item.isSelected)}
              onChange={() => onItemClick(item.id)}
            >
              <Icon name={item.icon} size={20}></Icon>
            </Toggle>
          </Align>
        );
      })}
    </StyledIconListMenu>
  );
};

export default IconListMenu;
