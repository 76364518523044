import { useCurrentFrame, useVideoConfig } from "remotion";
import type { PropertyAnimation } from "../types/types";
import { Easing, getTimeline } from "./animationUtils";
import { getCombinedCssProperties, getCssProperty } from "./cssUtils";

interface AnimatedProps {
  animations: PropertyAnimation[];
}

const cssAnimationMapping: Record<string, string> = {
  "letter-spacing": "letterSpacing",
};

export const useAnimationStyle = ({ animations }: AnimatedProps) => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  const ms = (frame / fps) * 1000;
  const cssPropertiesList: [string, (x: number) => unknown][] =
    getCombinedCssProperties(
      animations.map((animation) => {
        const timings = animation.timings;
        const timeline = getTimeline(timings, Easing.easeInQuad);
        return getCssProperty(animation.property, timeline);
      })
    );
  const cssPropertiesValue = Object.fromEntries(
    cssPropertiesList.map((prop) => [
      cssAnimationMapping[prop[0]] ?? prop[0],
      prop[1](ms),
    ])
  );
  return cssPropertiesValue;
};
