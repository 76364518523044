import type { Easing } from "./animationUtils";

export const DEFAULT_ANIMATION_DURATION = 330;
export interface TimingProps {
  from?: number;
  duration?: number;
}

export interface TimelineTimingProps {
  time: number;
  value: number;
  easing?: Easing;
}
