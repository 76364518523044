import { useState } from "react";
import { Button } from "../@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../@/components/ui/dialog";
import { Input } from "../@/components/ui/input";
import { Label } from "../@/components/ui/label";
import { Textarea } from "../@/components/ui/textarea";

interface FeedbackDialogProps {
  onOpenChange: (v: boolean) => void;
}

export const FeedbackDialog = ({ onOpenChange }: FeedbackDialogProps) => {
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const sendHelp = () => {
    fetch("/api/help", {
      method: "POST",
      body: JSON.stringify({ message, email }),
      headers: { "Content-Type": "application/json" },
    });
    onOpenChange(false);
  };
  return (
    <Dialog open onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[625px]">
        <DialogHeader>
          <DialogTitle>Ask for help & provide feedback</DialogTitle>
          <DialogDescription>
            Help us build something you love.
          </DialogDescription>
        </DialogHeader>
        <div className="w-full space-y-4">
          <div className="space-y-1">
            <Label>Message</Label>
            <Textarea onChange={(v) => setMessage(v.target.value)} />
          </div>

          <div className="space-y-1">
            <Label>Email</Label>
            <Input
              id="email"
              type="email"
              onChange={(v) => setEmail(v.target.value)}
            />
          </div>
          <Button onClick={sendHelp}>Send</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
