import styled from "styled-components";
import { useAnimationStyle } from "../../utils/useAnimationStyle";

interface ImageComponentProps {
  path: string;
  width?: number;
  height?: number;
  borderRound?: boolean;
  zoomAnimation?: boolean;
  withShadow?: boolean;
}

const ImageContainer = styled.span<{
  borderRound?: boolean;
  withShadow?: boolean;
}>`
  ${({ borderRound }) => {
    return borderRound ? "border-radius: 10px;" : "";
  }}
  ${({ withShadow }) => {
    return withShadow
      ? "box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;"
      : "";
  }}
  overflow: hidden;
`;

const ImageElement = styled.img<{
  borderRound?: boolean;
  zoomAnimation?: boolean;
}>`
  will-change: transform;
  transform-origin: 140px 90px;
  ${({ borderRound }) => {
    return borderRound ? "border-radius: 10px;" : "";
  }}
`;

const Image = ({ props }: { props: ImageComponentProps }) => {
  const scaleAnimation = useAnimationStyle({
    animations: [
      {
        property: "scale",
        timings: [
          {
            time: 800,
            value: 1,
          },
          {
            time: 1600,
            value: 1.5,
          },
          {
            time: 2000,
            value: 1,
          },
        ],
      },
    ],
  });

  return (
    <ImageContainer
      borderRound={props.borderRound}
      withShadow={props.withShadow}
    >
      <ImageElement
        borderRound={props.borderRound}
        src={props.path}
        style={{
          width: props.width ?? "auto",
          height: props.height ?? "auto",
          ...(props.zoomAnimation ? scaleAnimation : {}),
        }}
      ></ImageElement>
    </ImageContainer>
  );
};

export default Image;
