import type { ReactNode } from "react";

interface EditorItem<T> {
  label: string;
  getValueFromProps: (props: T) => any;
  setValueOnProps: (
    v: any,
    onPropertiesChangeFn: (newProps: Record<string, unknown>) => void
  ) => void; //depends on the editor input
  isMain?: boolean;
  type: string;
}
type Editors<T> = EditorItem<T>[];

interface AnimatedObjectDefinition<T> {
  key: string;
  title: string;
  getDisplay: (props: T) => ReactNode;
  getEditors?: () => Editors<T>;
}

const ANIMATED_OBJECTS: Record<string, AnimatedObjectDefinition<any>> = {};

export const registerAnimatedObject = <T>(
  animatedObjectDefinition: AnimatedObjectDefinition<T>
) => {
  ANIMATED_OBJECTS[animatedObjectDefinition.key] = animatedObjectDefinition;
};

export const getAnimatedObjectHandler = (key: string) => {
  const def = ANIMATED_OBJECTS[key];
  if (!def) {
    return undefined;
  }
  return {
    key: def.key,
    title: def.title,
    getDisplay: def.getDisplay,
    getEditors: def.getEditors ?? (() => []),
  };
};
