import type { PlayerRef } from "@remotion/player";
import { createContext, ReactNode, useContext } from "react";

const VideoDefinitionContext = createContext<{
  seekTime: (time: number) => void;
} | null>(null);

interface VideoControlProviderProps {
  playerRefCurrent: PlayerRef | null;
  fps: number;
  children: ReactNode;
}

export const useVideoControl = () => {
  const controls = useContext(VideoDefinitionContext);
  if (!controls) {
    throw Error("Missing controls");
  }
  return controls;
};

export const VideoControlProvider = ({
  playerRefCurrent,
  fps,
  children,
}: VideoControlProviderProps) => {
  const seekTime = (time: number) => {
    const frame = Math.floor((time * fps) / 1000);
    playerRefCurrent?.seekTo(frame);
  };
  const controls = {
    seekTime,
  };
  return (
    <VideoDefinitionContext.Provider value={controls}>
      {children}
    </VideoDefinitionContext.Provider>
  );
};
