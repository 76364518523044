import { getAnimatedObjectHandler } from "../types/animatedObjects/animatedObjects";
import type { PropertyAnimation, VAnimatedObject } from "../types/types";
import type { PreBuiltAnimationType } from "../utils/prebuiltAnimations";
import { AnimatedObject } from "./components/base/AnimatedObject";

interface AnimatedObjectDisplayerProps {
  object: VAnimatedObject;
}
export const AnimatedObjectDisplayer = ({
  object,
}: AnimatedObjectDisplayerProps) => {
  const animatedObject = getAnimatedObjectHandler(object.type);
  const diplayer = animatedObject?.getDisplay(object.props);
  const animations = Object.entries(object.animations) as [
    PreBuiltAnimationType | "custom",
    PropertyAnimation[]
  ][];
  const customAnimations = animations
    .filter(([key]) => key === "custom")
    .map(([key, value]) => value)
    .flat();
  const preBuiltAnimations = animations
    .filter(([key]) => key !== "custom")
    .map(([key, value]) => value)
    .flat();
  return (
    <AnimatedObject animations={customAnimations}>
      <AnimatedObject animations={preBuiltAnimations}>
        {diplayer}
      </AnimatedObject>
    </AnimatedObject>
  );
};
