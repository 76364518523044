import type { ReactNode } from "react";
import { registerAnimatedObject } from "./animatedObjects";

interface NodeProps {
  node: ReactNode;
}

registerAnimatedObject<NodeProps>({
  key: "node",
  title: "Node",
  getDisplay: (props) => props.node,
});
