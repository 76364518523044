import type { MouseEvent, ReactNode } from "react";
import type { TimingProps } from "../../utils/timings";
import type { VFrame } from "../types";

interface GetDisplay {
  sequence: VFrame;
  timing: Required<TimingProps>;
  zIndex: number;
}
interface GetIllustrationProps {
  onBackgroundClick: () => void;
  onElementClick: (spotKey: string, event: MouseEvent) => void;
  spotKeys: string[];
}

interface SpotSequenceDefinition {
  key: string;
  getDisplay: (props: GetDisplay) => ReactNode;
  getIllustration?: (props: GetIllustrationProps) => ReactNode;
}

const SPOT_SEQUENCES: Record<string, SpotSequenceDefinition> = {};

export const registerSpotSequence = (
  spotSequenceDefinition: SpotSequenceDefinition
) => {
  SPOT_SEQUENCES[spotSequenceDefinition.key] = spotSequenceDefinition;
};

export const getSpotSequenceHandler = (key: string) => {
  const def = SPOT_SEQUENCES[key];
  if (!def) {
    return undefined;
  }
  return {
    getDisplay: def.getDisplay,
    getIllustration: def.getIllustration,
    showSpotLabel: false,
  };
};
