import React from "react";
import styled from "styled-components";
import { getAnimatedObjectHandler, TextProps } from "three-modules";
import { Separator } from "../@/components/ui/separator";
import TextStylePreview from "../app-components/TextStyleReview";
import { FormGrid } from "../components/FormGrid";
import PreviewList from "../components/PreviewList";
import type { StoreAnimatedObject } from "../store/storeUtils";
import { editorMap, EditorPropertyType } from "./editors";
import { useStore } from "./VideoDefinitionStoreProvider";

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface ObjectEditionProps {
  object: StoreAnimatedObject;
  onObjectChange: (v: StoreAnimatedObject) => void;
}
interface ObjectEditorProps extends ObjectEditionProps {}

const onPropertiesChange =
  (
    object: StoreAnimatedObject,
    onObjectChange: (o: StoreAnimatedObject) => void
  ) =>
  (newProps: Record<string, unknown>) => {
    onObjectChange({
      ...object,
      props: { ...object.props, ...newProps },
    });
  };

const propsList: Omit<TextProps, "text">[] = [
  {
    font: "Poppins-ExtraBold",
    uppercase: true,
    fontSize: 20,
    color: "#000000",
  },
  {
    font: "Poppins-Regular",
    uppercase: false,
    fontSize: 20,
    color: "#000000",
  },
];
const textStyleItems = propsList.map((props, index) => ({
  id: index + "",
  label: "",
  component: () => <TextStylePreview textProps={props}></TextStylePreview>,
}));

export const editorSections = (type: string) => {
  if (type === "text") {
    return [
      ({ object, onObjectChange }: ObjectEditionProps) => (
        <React.Fragment key="style">
          <Separator className="my-4" />
          <div className="space-y-1 pb-4">
            <h4 className="text-sm font-medium leading-none">
              Text Style Library
            </h4>
            <p className="text-sm text-muted-foreground">
              Select ready to use text styles.
            </p>
          </div>
          <div>
            <PreviewList
              isHorizontal
              items={textStyleItems}
              onItemClick={(id) => {
                const propsIndex = textStyleItems.findIndex((i) => i.id === id);
                if (propsIndex !== -1) {
                  onPropertiesChange(
                    object,
                    onObjectChange
                  )({ ...propsList[propsIndex] });
                }
              }}
            ></PreviewList>
          </div>
        </React.Fragment>
      ),
    ];
  }
};

const ObjectEditor = ({ object, onObjectChange }: ObjectEditorProps) => {
  const store = useStore();
  const onPropertiesChangeFn = onPropertiesChange(object, onObjectChange);

  const objectHandler = getAnimatedObjectHandler(object.type);
  const editors = objectHandler?.getEditors();

  const objectEditorComponents = (editors ?? []).map(
    (propertyEditionDefinition, defIndex) => {
      const editor =
        editorMap[propertyEditionDefinition.type as EditorPropertyType];
      if (!editor) {
        throw Error("No editor for " + propertyEditionDefinition.type);
      }
      const value = propertyEditionDefinition.getValueFromProps(
        object.props
      ) as any;
      return editor({
        label: propertyEditionDefinition.label,
        store,
        value,
        onChange: (v) =>
          propertyEditionDefinition.setValueOnProps(v, onPropertiesChangeFn),
      });
    }
  );

  return (
    <>
      <div className="space-y-1 pb-4">
        <h4 className="text-sm font-medium leading-none">
          {objectHandler?.title} edition
        </h4>
        <p className="text-sm text-muted-foreground">
          Customization tools for {objectHandler?.title ?? "object"}.
        </p>
      </div>
      <FlexContainer key={object.id}>
        <div className="space-y-3">
          <FormGrid elements={objectEditorComponents} />
        </div>
        {editorSections(object.type)?.map((editorSection) =>
          editorSection({ object, onObjectChange })
        )}
      </FlexContainer>
    </>
  );
};

export default ObjectEditor;
