import { ReactNode, useState } from "react";
import styled from "styled-components";
import { Label } from "../@/components/ui/label";
import { sizes } from "./sizes";

interface PreviewListProps {
  isHorizontal?: boolean;
  items: PreviewListItem[];
  onItemClick: (id: string) => void;
}
export interface PreviewListItem {
  id: string;
  component: () => ReactNode;
  label?: string;
  isSelected?: boolean;
}

const StyledPreviewList = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  flex-wrap: wrap;
  border-radius: 5px;
`;

const PreviewItemContainer = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: ${sizes.padding.medium}px;
  border: 1px solid hsl(var(--border));
  border-color: hsl(var(--input));
  border-radius: 5px;
  line-height: 16px;
  padding-right: 4px;
`;
const PreviewItemCompoenent = styled.div`
  margin: ${sizes.padding.medium}px;
`;
const PreviewItemDescription = styled.div`
  text-overflow: ellipsis;
  width: 120px;
`;

const PreviewList = ({
  items,
  onItemClick,
  isHorizontal,
}: PreviewListProps) => {
  const [isHovered, setIsHovered] = useState<number>();

  return (
    <StyledPreviewList
      style={{
        flexDirection: isHorizontal ? "row" : "column",
      }}
    >
      {items.map((item, index) => {
        let border = `1px solid transparent`;
        if (item.isSelected) {
          border = `1px solid hsl(var(--foreground))`;
        } else if (isHovered === index) {
          border = `1px solid hsl(var(--border))`;
        }
        return (
          <PreviewItemContainer
            key={item.id}
            onMouseLeave={() => setIsHovered(undefined)}
            onMouseEnter={() => setIsHovered(index)}
            onClick={() => onItemClick(item.id)}
            style={{
              border,
            }}
          >
            <PreviewItemCompoenent>{item.component()}</PreviewItemCompoenent>
            {item.label && (
              <PreviewItemDescription>
                <Label style={{ lineHeight: 0.5 }}>{item.label}</Label>
              </PreviewItemDescription>
            )}
          </PreviewItemContainer>
        );
      })}
    </StyledPreviewList>
  );
};

export default PreviewList;
