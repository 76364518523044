import { Player, PlayerRef } from "@remotion/player";
import type { Ref } from "react";
import { Video, type VideoDefinition } from "three-modules";

interface VideoPlayerProps {
  videoDefinition: VideoDefinition;
  fps: number;
  durationInFrames: number;
  playerRef?: Ref<PlayerRef> | undefined;
}
export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  videoDefinition,
  playerRef,
  fps,
  durationInFrames,
}) => {
  const width = 500;
  const height = 300;
  return (
    <Player
      ref={playerRef}
      inputProps={{ videoDefinition }}
      component={Video}
      durationInFrames={durationInFrames}
      compositionWidth={width}
      compositionHeight={height}
      fps={fps}
      style={{
        width: "auto",
        height: "100%",
      }}
      controls
    />
  );
};

export default VideoPlayer;
