export const theme = {
  colors: {
    primary: "#ff00ff",
    border: "#E0E0E0",
  },
  text: {
    label: "#686978",
  },
};

export const fonts = {
  label: {
    "font-family": "Poppins-Regular",
    "font-size": "1.2rem",
    "font-weight": "bold",
    color: theme.text.label,
  },
};
