import type { VAnimatedObject } from "three-modules";
import type { StoreFrame } from "../store/storeUtils";

const videoObject2RichTextObject = (
  animatedObject: VAnimatedObject & { id: string }
) => {
  let result: Record<string, unknown> = {
    text: "Not supported type",
    type: "custom-text",
    objectData: {
      id: "firstObject.id",
    },
  };
  if (!animatedObject) {
    return undefined;
    // throw Error("No object in sequence:");
  }
  if (animatedObject.type === "text") {
    result = {
      text: animatedObject.props.text as string,
      type: "custom-text",
      objectData: {
        id: animatedObject.id,
      },
    };
  }
  if (animatedObject.type === "node") {
    result = {
      text: "node here",
      type: "custom-text",
      objectData: {
        id: animatedObject.id,
      },
    };
  }
  if (animatedObject.type === "image") {
    result = {
      objectData: {
        id: animatedObject.id,
      },
      height: animatedObject.props.height ?? 43,
      width: animatedObject.props.width ?? 200,
      maxWidth: 500,
      showCaption: false,
      imageType: animatedObject.props.imageType,
      value: animatedObject.props.value,
      type: "image",
      altText: "",
      caption: {
        editorState: {
          root: {
            children: [],
            type: "root",
          },
        },
      },
    };
  }
  return result;
};

const linebreakElement = {
  type: "linebreak",
};
// Maybe this should rely on store and not on videoDefinition ?
export const videoDefinition2RichText = (videoDefinition: {
  frames: StoreFrame[];
}) => {
  const jsonFrames = videoDefinition.frames.map((frame) => {
    const lines = frame.elements;
    const lineObjects = lines.map((line) => {
      return line
        .filter((columnSequences) => columnSequences.length > 0)
        .flatMap((columnSequences, index) => {
          if (columnSequences.length === 1) {
            return videoObject2RichTextObject(columnSequences[0].objects[0]);
          } else {
            return {
              rows: [
                {
                  cells: columnSequences.map((columnSequence) => {
                    const element = videoObject2RichTextObject(
                      columnSequence.objects[0]
                    );
                    return {
                      colSpan: 1,
                      id: columnSequence.id,
                      data: element,
                      type: "normal",
                      width: null,
                    };
                  }),
                  height: null,
                  id: "arfvf",
                },
              ],
              type: "tablesheet",
            };
          }
        })
        .filter((a) => a !== undefined);
    });
    const lineObjectWithLinebreaks = lineObjects.flatMap((line, index) => [
      ...line,
      ...(index !== lineObjects.length - 1 ? [linebreakElement] : []),
    ]);
    return {
      type: "custom-paragraph",
      frameData: {
        id: frame.id,
      },
      children: lineObjectWithLinebreaks,
    };
  });
  return {
    root: {
      type: "root",
      children: jsonFrames,
    },
  };
};
