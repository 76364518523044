import hexRgb from "hex-rgb";
import rgbHex from "rgb-hex";

// https://easings.net/
//https://mauriciopoppe.github.io/function-plot/
export enum Easing {
  "linear" = "linear",
  "easeOutQuint" = "easeOutQuint",
  "easeOutCubic" = "easeOutCubic",
  "easeOutCirc" = "easeOutCirc",
  "easeInQuint" = "easeInQuint",
  "easeInCubic" = "easeInCubic",
  "easeInQuad" = "easeInQuad",
  "easeInExpo" = "easeInExpo",
  "easeOutQuad" = "easeOutQuad",
  "easeInQuart" = "easeInQuart",
  "easeOutQuart" = "easeOutQuart",
  "easeOutBack" = "easeOutBack",
  "bounce" = "bounce",
}

const easingFunction: Record<Easing, (x: number) => number> = {
  [Easing.linear]: (x: number): number => {
    return x;
  },
  [Easing.easeOutQuint]: (x: number): number => {
    return 1 - Math.pow(1 - x, 5);
  },
  [Easing.easeOutQuint]: (x: number): number => {
    const c1 = 1.70158;
    const c3 = c1 + 1;
    return 1 + c3 * Math.pow(x - 1, 3) + c1 * Math.pow(x - 1, 2);
  },
  [Easing.easeInQuint]: (x: number): number => {
    return x * x * x * x * x;
  },
  [Easing.easeOutCubic]: (x: number): number => {
    return 1 - Math.pow(1 - x, 3);
  },
  [Easing.easeInQuad]: (x: number): number => {
    return x * x;
  },
  [Easing.easeOutQuad]: (x: number): number => {
    return 1 - (1 - x) * (1 - x);
  },
  [Easing.easeInCubic]: (x: number): number => {
    return x * x * x;
  },
  [Easing.easeOutCirc]: (x: number): number => {
    return Math.sqrt(1 - Math.pow(x - 1, 2));
  },
  [Easing.easeInQuart]: (x: number): number => {
    return x * x * x * x;
  },
  [Easing.easeInExpo]: (x: number): number => {
    return x === 0 ? 0 : Math.pow(2, 10 * x - 10);
  },
  [Easing.easeOutQuart]: (x: number): number => {
    return 1 - Math.pow(1 - x, 4);
  },
  [Easing.easeOutBack]: (x: number): number => {
    const c1 = 1.70158;
    const c3 = c1 + 1;

    return 1 + c3 * Math.pow(x - 1, 3) + c1 * Math.pow(x - 1, 2);
  },
  [Easing.bounce]: (x: number): number => {
    return 1.3 - 2.5 * Math.pow(x - 0.7, 2);
  },
};

type TwinFunction<T> = (
  startY: T,
  endY: T,
  startX: number,
  endX: number,
  easing: Easing
) => (x: number) => T;

export const getColorTwin: TwinFunction<string> =
  (startY, endY, startX, endX, easing) => (x) => {
    let xReal = x;
    if (x < startX) {
      xReal = startX;
    } else if (x > endX) {
      xReal = endX;
    }
    const { red: r1, blue: b1, green: g1 } = hexRgb(startY.replace("#", ""));
    const { red: r2, blue: b2, green: g2 } = hexRgb(endY.replace("#", ""));

    const r = getTwin(r1, r2, startX, endX, easing)(x);
    const g = getTwin(g1, g2, startX, endX, easing)(x);
    const b = getTwin(b1, b2, startX, endX, easing)(x);
    // xReal = easingFunction[easing](x);
    return "#" + rgbHex(r, g, b, 1);
  };

export const getTwin: TwinFunction<number> =
  (startY, endY, startX, endX, easing) => (x) => {
    let xReal = x;
    if (x < startX) {
      xReal = startX;
    } else if (x > endX) {
      xReal = endX;
    }
    // xReal = easingFunction[easing](x);
    return (
      startY +
      easingFunction[easing]((xReal - startX) / (endX - startX)) *
        (endY - startY)
    );
  };

export interface TimelineValue {
  time: number;
  value: unknown;
  easing?: Easing;
}
export const getTimeline = (
  timelineValues: TimelineValue[],
  easing?: Easing
) => {
  if (!timelineValues || timelineValues.length === 0) {
    throw Error("invalid timeline");
  } else {
  }
  return (x: number) => {
    if (timelineValues.length === 1) {
      return timelineValues[0].value;
    }
    if (timelineValues.length > 0 && x < timelineValues[0].time) {
      return timelineValues[0].value;
    }
    if (
      timelineValues.length > 0 &&
      x > timelineValues[timelineValues.length - 1]?.time
    ) {
      return timelineValues[timelineValues.length - 1].value;
    }
    for (let i = 0; i < timelineValues.length - 1; i++) {
      const from = timelineValues[i];
      const to = timelineValues[i + 1];
      if (!to.time) {
        return from.value;
      }
      if (x >= from.time && x <= to.time) {
        const twinFunction: TwinFunction<any> =
          typeof from.value !== "number" ? getColorTwin : getTwin;
        const twin = twinFunction(
          from.value as any,
          to.value,
          from.time,
          to.time,
          from.easing ?? easing ?? Easing.linear
        );
        return twin(x);
      }
    }
    throw Error("Not in ranges");
  };
};
