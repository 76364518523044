import type { ReactNode } from "react";
import { useCurrentFrame, useVideoConfig } from "remotion";
import useResizeObserver from "use-resize-observer";
import { Easing, getTwin } from "../../../utils/animationUtils";
import {
  getCombinedCssProperties,
  getCssProperty,
} from "../../../utils/cssUtils";

interface AnimatedTextProps {
  children: ReactNode;
}

const AnimatedRgbTextObject: React.FC<AnimatedTextProps> = ({
  children,
}: AnimatedTextProps) => {
  const frame = useCurrentFrame();
  const { durationInFrames, fps } = useVideoConfig();
  const durationInMs = (durationInFrames / fps) * 1000;
  const ms = (frame / fps) * 1000;

  const { ref, width, height } = useResizeObserver();
  const scaleStart = 0.4;
  const scaleEnd = 1;
  const scaleRed = getCombinedCssProperties([
    getCssProperty(
      "scale",
      getTwin(scaleStart, scaleEnd, 0, 0.6 * durationInMs, Easing.easeOutCubic)
    ),
  ])[0];
  const scaleGreen = getCombinedCssProperties([
    getCssProperty(
      "scale",
      getTwin(
        scaleStart,
        scaleEnd,
        0.06 * durationInMs,
        0.6 * durationInMs,
        Easing.easeOutCubic
      )
    ),
  ])[0];
  const scaleBlue = getCombinedCssProperties([
    getCssProperty(
      "scale",
      getTwin(scaleStart, scaleEnd, 0, 0.5 * durationInMs, Easing.easeOutQuint)
    ),
  ])[0];

  const channelSplitStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    mixBlendMode: "lighten",
  } as const;

  return (
    <div
      style={{
        height,
        width,
        position: "relative",
      }}
    >
      <span
        ref={ref}
        style={{
          ...channelSplitStyle,
          color: "#0f0",
          ...{ [scaleGreen[0]]: scaleGreen[1](ms) },
        }}
      >
        {children}
      </span>
      <span
        style={{
          ...channelSplitStyle,
          color: "#00f",
          ...{ [scaleBlue[0]]: scaleBlue[1](ms) },
        }}
      >
        {children}
      </span>
      <span
        style={{
          ...channelSplitStyle,
          color: "#f00",
          ...{ [scaleRed[0]]: scaleRed[1](ms) },
        }}
      >
        {children}
      </span>
    </div>
  );
};

export default AnimatedRgbTextObject;
