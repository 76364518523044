import type { VFrame, VideoDefinition, VSequence } from "three-modules";
import type { FileRecord } from "three-modules/src/types/types";
import { ObjectTypes, SOUND, STARTID } from "../store/storeUtils";
import type { VideoDefinitionStore } from "../store/videoDefinitionStore";

interface CreateSubsequenceProps {
  store: VideoDefinitionStore;
  line: number;
  column: number;
  sequence: VSequence;
  frameId: string;
}

export const createSequence = ({
  store,
  line,
  column,
  sequence,
  frameId,
}: CreateSubsequenceProps) => {
  const { objects, ...data } = sequence;
  const sequenceId = store.createObject({
    type: ObjectTypes.sequence,
    position: [line, column],
    frameId,
    data,
  });
  sequence.objects?.forEach((object) => {
    const oId = store.createObject({
      type: ObjectTypes.object,
      sequenceId: sequenceId,
      data: object,
    });
  });
};

interface CreateFrame {
  store: VideoDefinitionStore;
  frame: VFrame;
  files: FileRecord;
  currentFramerId: string;
}
export const overrideVFrameInStore = ({
  store,
  frame,
  files,
  currentFramerId,
}: CreateFrame) => {
  const currentFrame = store.getObject(currentFramerId);
  store.updateObject(currentFramerId, {
    ...currentFrame,
    data: {
      ...currentFrame.data,
      duration: frame.duration ?? currentFrame.data.duration,
      background: frame.background ?? currentFrame.data.background,
    },
  });
  const oldSequences = store.byType(ObjectTypes.sequence).filter((seq) => {
    return seq.frameId === currentFramerId;
  });
  oldSequences.forEach((seq) => store.deleteObject(seq.id));
  frame.elements.forEach((line, lineIndex) => {
    line.forEach((columnSequences, columnIndex) => {
      columnSequences.forEach((sequence) => {
        createSequence({
          store,
          line: lineIndex,
          column: columnIndex,
          sequence,
          frameId: currentFramerId,
        });
      });
    });
  });
  Object.values(files).forEach((file) => {
    store.updateObject(file.id, {
      type: ObjectTypes.file,
      value: file.value,
    });
  });
};

interface ConvertDefinitionToStoreProps {
  store: VideoDefinitionStore;
  videoDefinition: VideoDefinition;
}
export const videoDefinition2Store = ({
  store,
  videoDefinition,
}: ConvertDefinitionToStoreProps) => {
  store.clear();

  const frames = videoDefinition.frames;
  let nextSequenceId: string | undefined = undefined;
  frames.reverse().forEach((frame, index) => {
    const { elements, ...data } = frame;
    const frameId = store.createObject({
      type: ObjectTypes.frame,
      data: {
        ...data,
        duration: frame.duration,
        nextSequenceId,
      },
    });
    if (index === frames.length - 1) {
      store.updateObject(STARTID, { startId: frameId });
    }
    nextSequenceId = frameId;

    frame.elements.forEach((line, lineIndex) => {
      line.forEach((columnSequences, columnIndex) => {
        columnSequences?.forEach((sequence) => {
          createSequence({
            store,
            line: lineIndex,
            column: columnIndex,
            sequence,
            frameId,
          });
        });
      });
    });
  });
  
  if (videoDefinition.sound) {
    store.updateObject(SOUND, videoDefinition.sound);
  }
  if (videoDefinition.files) {
    //TODO do not add files that are not used
    Object.values(videoDefinition.files).forEach((file) => {
      store.updateObject(file.id, {
        type: ObjectTypes.file,
        value: file.value,
      });
    });
  }
};
