import { forwardRef, ReactNode } from "react";
import type { PropertyAnimation } from "../../../types/types";
import { useAnimationStyle } from "../../../utils/useAnimationStyle";

interface AnimatedTextProps {
  children: ReactNode;
  animations: PropertyAnimation[];
}
export const AnimatedObject = forwardRef<HTMLDivElement, AnimatedTextProps>(
  ({ children, animations }, ref) => {
    const cssPropertiesValue = useAnimationStyle({ animations });
    return (
      <div
        ref={ref}
        style={{
          display: "flex",
          ...cssPropertiesValue,
        }}
      >
        {children}
      </div>
    );
  }
);
