import ReactDOM from "react-dom";
import { v4 as uuidv4 } from "uuid";
import createGlobalObserver from "./globalObservers";

export const createStore = () => {
  const store = new Map<string, any>();

  const globalObservers = createGlobalObserver<never>();

  const createObject = (object: Record<string, any>) => {
    const id = uuidv4();
    updateObject(id, object);
    return id;
  };

  const updateObject = (id: string, newObject: Record<string, any>) => {
    store.set(id, { id, ...newObject });
    ReactDOM.unstable_batchedUpdates(() => {
      globalObservers.notify();
    });
  };
  const deleteObject = (id: string) => {
    store.delete(id);
    ReactDOM.unstable_batchedUpdates(() => {
      globalObservers.notify();
    });
  };

  const getObject = (id: string) => {
    return store.get(id);
  };

  const getAll = () => {
    return store.values();
  };
  
  const clear = () => {
    store.clear();
  };

  return {
    createObject,
    updateObject,
    deleteObject,
    getObject,
    getAll,
    clear,
    globalObservers,
  };
};
