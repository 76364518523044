import type { FunctionComponent } from "react";
import styled from "styled-components";

export enum IconName {
  "burger" = "burger",
  "add" = "add",
  "uppercase" = "uppercase",
  "italic" = "italic",
  "copy" = "copy",
  "paste" = "paste",
  "underline" = "underline",
  "split" = "split",
  "open" = "open",
  "code" = "code",
  "frame" = "frame",
  "selection" = "selection",
  "video" = "video",
  "sound" = "sound",
  "text" = "text",
  "image" = "image",
  "help" = "help",
  "wait" = "wait",
}

export const icons = {
  uppercase: ({ style }: { style: Record<string, unknown> }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={style}>
      <path
        clipRule="evenodd"
        d="M13 9H10V17H8V9H5V7H13V9ZM18 13H16V17H14V13H12V11H18V13Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  ),
  add: ({ style }: { style: Record<string, unknown> }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={style}>
      <path d="M2 5H14V7H2V5Z" fill="currentColor" />
      <path d="M2 9H14V11H2V9Z" fill="currentColor" />
      <path d="M10 13H2V15H10V13Z" fill="currentColor" />
      <path d="M16 9H18V13H22V15H18V19H16V15H12V13H16V9Z" fill="currentColor" />
    </svg>
  ),
  burger: ({ style }: { style: Record<string, unknown> }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" style={style}>
      <g data-name="Layer 2" id="Layer_2">
        <path d="M28,10H4A1,1,0,0,1,4,8H28a1,1,0,0,1,0,2Z" />
        <path d="M28,17H4a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z" />
        <path d="M28,24H4a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z" />
      </g>
      <g id="frame">
        <rect fill="none" height="32" width="32" />
      </g>
    </svg>
  ),
  underline: ({ style }: { style: Record<string, unknown> }) => (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M6 10V4H8V10C8 12.2091 9.79086 14 12 14C14.2091 14 16 12.2091 16 10V4H18V10C18 13.3137 15.3137 16 12 16C8.68629 16 6 13.3137 6 10Z"
        fill="currentColor"
      />
      <path
        d="M7 18C6.44772 18 6 18.4477 6 19C6 19.5523 6.44771 20 7 20H17C17.5523 20 18 19.5523 18 19C18 18.4477 17.5523 18 17 18H7Z"
        fill="currentColor"
      />
    </svg>
  ),
  code: ({ style }: { style: Record<string, unknown> }) => (
    <svg
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path d="M0 0h48v48H0V0z" fill="none" />
      <path d="M18.8 33.2L9.7 24l9.2-9.2L16 12 4 24l12 12 2.8-2.8zm10.4 0l9.2-9.2-9.2-9.2L32 12l12 12-12 12-2.8-2.8z" />
    </svg>
  ),
  italic: ({ style }: { style: Record<string, unknown> }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={style}>
      <path
        d="M11.4903 5.45801H17.4903L16.7788 7.32716H14.7788L11.2212 16.6729H13.2212L12.5097 18.5421H6.5097L7.22122 16.6729H9.22122L12.7788 7.32716H10.7788L11.4903 5.45801Z"
        fill="currentColor"
      />
    </svg>
  ),
  copy: ({ style }: { style: Record<string, unknown> }) => (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5028 4.62704L5.5 6.75V17.2542C5.5 19.0491 6.95507 20.5042 8.75 20.5042L17.3663 20.5045C17.0573 21.3782 16.224 22.0042 15.2444 22.0042H8.75C6.12665 22.0042 4 19.8776 4 17.2542V6.75C4 5.76929 4.62745 4.93512 5.5028 4.62704ZM17.75 2C18.9926 2 20 3.00736 20 4.25V17.25C20 18.4926 18.9926 19.5 17.75 19.5H8.75C7.50736 19.5 6.5 18.4926 6.5 17.25V4.25C6.5 3.00736 7.50736 2 8.75 2H17.75ZM17.75 3.5H8.75C8.33579 3.5 8 3.83579 8 4.25V17.25C8 17.6642 8.33579 18 8.75 18H17.75C18.1642 18 18.5 17.6642 18.5 17.25V4.25C18.5 3.83579 18.1642 3.5 17.75 3.5Z"
        fill="#212121"
      />
    </svg>
  ),
  paste: ({ style }: { style: Record<string, unknown> }) => (
    <svg viewBox="0 0 20 20" style={style} xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5 20H2a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h1V3l2.03-.4a3 3 0 0 1 5.94 0L13 3v1h1a2 2 0 0 1 2 2v1h-2V6h-1v1H3V6H2v12h5v2h3.5zM8 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm2 4h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2zm0 2v8h8v-8h-8z" />
    </svg>
  ),
  split: ({ style }: { style: Record<string, unknown> }) => (
    <svg
      id="icon"
      style={style}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title />
      <rect height="24" width="2" x="15" y="4" />
      <path d="M10,7V25H4V7h6m0-2H4A2,2,0,0,0,2,7V25a2,2,0,0,0,2,2h6a2,2,0,0,0,2-2V7a2,2,0,0,0-2-2Z" />
      <path d="M28,7V25H22V7h6m0-2H22a2,2,0,0,0-2,2V25a2,2,0,0,0,2,2h6a2,2,0,0,0,2-2V7a2,2,0,0,0-2-2Z" />
      <rect
        style={{ fill: "none" }}
        data-name="&lt;Transparent Rectangle&gt;"
        height="32"
        id="_Transparent_Rectangle_"
        width="32"
      />
    </svg>
  ),
  open: ({ style }: { style: Record<string, unknown> }) => (
    <svg
      height="24"
      version="1.1"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.016 3h6.984v6.984h-2.016v-3.563l-9.797 9.797-1.406-1.406 9.797-9.797h-3.563v-2.016zM18.984 18.984v-6.984h2.016v6.984c0 1.078-0.938 2.016-2.016 2.016h-13.969c-1.125 0-2.016-0.938-2.016-2.016v-13.969c0-1.078 0.891-2.016 2.016-2.016h6.984v2.016h-6.984v13.969h13.969z" />
    </svg>
  ),
  frame: ({ style }: { style: Record<string, unknown> }) => (
    <svg
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path d="M0 0h48v48H0V0z" fill="none" />
      <path d="M42 6H6c-2.2 0-4 1.8-4 4v28c0 2.2 1.8 4 4 4h36c2.2 0 4-1.8 4-4V10c0-2.2-1.8-4-4-4zm0 26H6V10h36v22z" />
    </svg>
  ),
  selection: ({ style }: { style: Record<string, unknown> }) => (
    <svg
      style={style}
      version="1.1"
      viewBox="0 0 368.5 442.4"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M352.4,242.2L352.4,242.2L72.5,10.2C58.4-1.5,38.8-3.3,22.8,5.6C6.7,14.5-2.1,32,0.4,50.2l47.5,353.9  c2.4,18.2,14.9,32.3,32.6,36.9c3.7,1,7.5,1.4,11.2,1.4c13.9,0,27.2-6.5,35.8-18.1c46.3-62.5,120.4-101.3,198.2-103.8  c18.5-0.6,34.4-12.3,40.4-29.9C372,273,366.7,254,352.4,242.2z M88.3,410.7c-2.5-0.6-8.4-3-9.4-10.7L31.4,46c-1.1-8.1,4.7-12,6.5-13  c1-0.6,3.4-1.7,6.5-1.7c2.4,0,5.2,0.7,8,3l279.9,231.9l0,0c6.4,5.3,4.7,12.2,4,14.2s-3.5,8.4-11.7,8.7  c-87.3,2.8-170.4,46.4-222.4,116.5C97.3,412.4,90.3,411.2,88.3,410.7z" />
    </svg>
  ),
  video: ({ style }: { style: Record<string, unknown> }) => (
    <svg style={style} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM10.622 8.415l4.879 3.252a.4.4 0 0 1 0 .666l-4.88 3.252a.4.4 0 0 1-.621-.332V8.747a.4.4 0 0 1 .622-.332z" />
      </g>
    </svg>
  ),
  sound: ({ style }: { style: Record<string, unknown> }) => (
    <svg
      fill="none"
      style={style}
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M12 8.95439L17.6352 7.82736C19.0102 7.55235 20 6.34501 20 4.94274C20 3.08637 18.3017 1.69406 16.4813 2.05813L12.4117 2.87206C11.0094 3.15252 10 4.38376 10 5.8138V13.8895C9.41165 13.5492 8.72857 13.3544 8 13.3544C5.79086 13.3544 4 15.1453 4 17.3544C4 19.5635 5.79086 21.3544 8 21.3544C10.2091 21.3544 12 19.5635 12 17.3544V8.95439ZM16.8736 4.01929L12.8039 4.83322C12.3365 4.92671 12 5.33712 12 5.8138V6.91479L17.2429 5.8662C17.6831 5.77816 18 5.39165 18 4.94274C18 4.34846 17.4563 3.90274 16.8736 4.01929ZM10 17.3544C10 16.2498 9.10457 15.3544 8 15.3544C6.89543 15.3544 6 16.2498 6 17.3544C6 18.459 6.89543 19.3544 8 19.3544C9.10457 19.3544 10 18.459 10 17.3544Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  ),
  image: ({ style }: { style: Record<string, unknown> }) => (
    <svg style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Layer 2" id="Layer_2">
        <path d="M26,27H6a3,3,0,0,1-3-3V8A3,3,0,0,1,6,5H26a3,3,0,0,1,3,3V24A3,3,0,0,1,26,27ZM6,7A1,1,0,0,0,5,8V24a1,1,0,0,0,1,1H26a1,1,0,0,0,1-1V8a1,1,0,0,0-1-1Z" />
        <path d="M21,15a3,3,0,1,1,3-3A3,3,0,0,1,21,15Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,21,11Z" />
        <path d="M26,27a1,1,0,0,1-.83-.45l-4.34-6.5a1,1,0,0,0-1.66,0l-.34.5a1,1,0,0,1-1.66-1.1l.33-.51a3,3,0,0,1,5,0l4.33,6.51a1,1,0,0,1-.28,1.38A.94.94,0,0,1,26,27Z" />
        <path d="M6,27a1,1,0,0,1-.54-.16,1,1,0,0,1-.3-1.38l6.23-9.62a3,3,0,0,1,2.5-1.37h0a3,3,0,0,1,2.5,1.34l6.42,9.64a1,1,0,0,1-1.66,1.1l-6.43-9.63a1,1,0,0,0-.83-.45h0a1,1,0,0,0-.83.46L6.84,26.54A1,1,0,0,1,6,27Z" />
      </g>
    </svg>
  ),
  text: ({ style }: { style: Record<string, unknown> }) => (
    <svg
      style={style}
      fill="none"
      height="24"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline fill="none" points="4 7 4 4 20 4 20 7" />
      <line x1="9" x2="15" y1="20" y2="20" />
      <line x1="12" x2="12" y1="4" y2="20" />
    </svg>
  ),
  help: ({ style }: { style: Record<string, unknown> }) => (
    <svg style={style} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM256 336c-18 0-32 14-32 32s13.1 32 32 32c17.1 0 32-14 32-32S273.1 336 256 336zM289.1 128h-51.1C199 128 168 159 168 198c0 13 11 24 24 24s24-11 24-24C216 186 225.1 176 237.1 176h51.1C301.1 176 312 186 312 198c0 8-4 14.1-11 18.1L244 251C236 256 232 264 232 272V288c0 13 11 24 24 24S280 301 280 288V286l45.1-28c21-13 34-36 34-60C360 159 329 128 289.1 128z" />
    </svg>
  ),
  wait: ({ style }: { style: Record<string, unknown> }) => (
    <svg
      id="Icons"
      style={style}
      version="1.1"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M16,2c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1s1-0.4,1-1V3C17,2.4,16.6,2,16,2z" />
        <path d="M7.5,6.1c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l3.5,3.5c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L7.5,6.1   z" />
        <path d="M9,16c0-0.6-0.4-1-1-1H3c-0.6,0-1,0.4-1,1s0.4,1,1,1h5C8.6,17,9,16.6,9,16z" />
        <path d="M9.6,20.9l-3.5,3.5c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l3.5-3.5c0.4-0.4,0.4-1,0-1.4   S10,20.6,9.6,20.9z" />
        <path d="M16,23c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1s1-0.4,1-1v-5C17,23.4,16.6,23,16,23z" />
        <path d="M22.4,20.9c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l3.5,3.5c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4   L22.4,20.9z" />
        <path d="M29,15h-5c-0.6,0-1,0.4-1,1s0.4,1,1,1h5c0.6,0,1-0.4,1-1S29.6,15,29,15z" />
        <path d="M21.7,11.3c0.3,0,0.5-0.1,0.7-0.3l3.5-3.5c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-3.5,3.5c-0.4,0.4-0.4,1,0,1.4   C21.1,11.2,21.4,11.3,21.7,11.3z" />
      </g>
    </svg>
  ),
};

interface IconProps {
  name: IconName;
  size?: string | number;
}

const StyledIcon = styled.div`
  display: flex;
  alignitems: center;
  justifycontent: center;
`;

const Icon: FunctionComponent<IconProps> = ({ name, size = "1.2rem" }) => {
  const style: Record<string, unknown> = {
    fill: "currentColor",
    width: size,
    minWidth: size,
    height: size,
    minHeight: size,
    pointerEvents: "none",
  };
  const svg = icons[name]?.({ style });

  let component;
  component = svg;

  return <StyledIcon>{component}</StyledIcon>;
};

export default Icon;
