import type { ReactNode } from "react";
import styled from "styled-components";
import { sizes } from "./sizes";

const AlignContainer = styled.div`
  display: flex;
  gap: ${sizes.padding.medium}px;
  align-items: center;
`;

interface AlignProps {
  children: ReactNode;
}
const Align = ({ children }: AlignProps) => {
  return <AlignContainer>{children}</AlignContainer>;
};

export default Align;
