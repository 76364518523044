import styled from "styled-components";
import type { PropertyAnimation, VAnimatedObject } from "three-modules";
import { CSS_PROPERTIES, PreBuiltAnimationType } from "three-modules";
import { Button } from "../@/components/ui/button";
import Align from "../components/Align";
import Icon, { IconName } from "../components/icons";
import NumberInput from "../components/NumberInput";
import { Select } from "../components/Select";
import { useVideoControl } from "./VideoControlProvider";

const AnimationLabel = styled.div`
  font-weight: bold;
`;
const AnimationContainer = styled.div`
  font-family: "Poppins-Regular";
  font-size: 14px;
  padding: 4px;
`;
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const PropertyKeyFrameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
interface ObjectAnimationEditorProps {
  animationRecord: VAnimatedObject["animations"];
  onAnimationsChange: (animations: VAnimatedObject["animations"]) => void;
}

const ObjectAnimationsEditor = ({
  animationRecord,
  onAnimationsChange,
}: ObjectAnimationEditorProps) => {
  const { seekTime } = useVideoControl();

  const onClickTime = (time: number) => {
    seekTime(time);
  };

  const isValidAnimation = (a: PropertyAnimation) => {
    return (
      a.property &&
      a.timings.every((t) => t.time !== undefined && t.value !== undefined)
    );
  };
  const onTimingAnimationValueChange = (
    animationKey: PreBuiltAnimationType | "custom",
    animationIndex: number,
    timingIndex: number,
    time: number,
    value: number
  ) => {
    const newAnimations = [
      ...(animationRecord[animationKey] ?? []).map((a, index) =>
        index === animationIndex
          ? {
              ...a,
              timings: a.timings.map((t, ti) =>
                ti === timingIndex ? { time, value } : t
              ),
            }
          : a
      ),
    ];
    onAnimationsChange({
      ...animationRecord,
      [animationKey]: newAnimations.filter(isValidAnimation),
    });
  };
  return (
    <div>
      {(
        Object.entries(animationRecord) as [
          PreBuiltAnimationType | "custom",
          PropertyAnimation[]
        ][]
      ).flatMap(([animationKey, animations]) =>
        animations.map((propertyAnimation, animationIndex) => {
          return (
            <AnimationContainer key={propertyAnimation.property}>
              <div className="space-y-2">
                <TitleContainer>
                  Property:
                  <AnimationLabel>{propertyAnimation.property}</AnimationLabel>
                </TitleContainer>
                {propertyAnimation.timings.map((timing, timingIndex) => (
                  <PropertyKeyFrameContainer key={timingIndex}>
                    <Align>
                      <div onClick={() => onClickTime(timing.time)}>⏱</div>
                      <NumberInput
                        value={timing.time}
                        onChange={(v) =>
                          onTimingAnimationValueChange(
                            animationKey,
                            animationIndex,
                            timingIndex,
                            v,
                            timing.value
                          )
                        }
                      />
                      <NumberInput
                        value={timing.value}
                        onChange={(v) =>
                          onTimingAnimationValueChange(
                            animationKey,
                            animationIndex,
                            timingIndex,
                            timing.time,
                            v
                          )
                        }
                      />
                      {timingIndex === propertyAnimation.timings.length - 1 && (
                        <Button
                          variant="outline"
                          onClick={() =>
                            onAnimationsChange({
                              ...animationRecord,
                              [animationKey]: [
                                ...(animationRecord[animationKey] ?? []).map(
                                  (a, i) =>
                                    animationIndex === i
                                      ? {
                                          ...a,
                                          timings: [
                                            ...a.timings,
                                            {
                                              time:
                                                (a.timings.at(-1)?.time ?? 0) +
                                                1,
                                              value: 0,
                                            },
                                          ],
                                        }
                                      : a
                                ),
                              ],
                            })
                          }
                        >
                          <Icon name={IconName.add} size={20}></Icon>
                        </Button>
                      )}
                    </Align>
                  </PropertyKeyFrameContainer>
                ))}
              </div>
            </AnimationContainer>
          );
        })
      )}
      <AnimationContainer>
        <TitleContainer>
          Property:
          <AnimationLabel>
            <Select
              value={""}
              onChange={(e) => {
                onAnimationsChange({
                  ...animationRecord,
                  ["custom"]: [
                    ...(animationRecord["custom"] ?? []),
                    {
                      property: e,
                      timings: [{ time: 0, value: 1 }],
                    },
                  ],
                });
              }}
              items={CSS_PROPERTIES}
            />
          </AnimationLabel>
        </TitleContainer>
      </AnimationContainer>
    </div>
  );
};

export default ObjectAnimationsEditor;
