import { Audio, staticFile, useVideoConfig } from "remotion";
import type { SoundProps } from "../../types/types";

const soundValues: Record<string, string> = {
  podcastIntro: "the-podcast-intro-111863.mp3",
  bounce: "bounce-114024.mp3",
  hiphop: "hip-hop-rock-beats-118000.mp3",
};

interface SoundComponentProps {
  sound: SoundProps;
}

const Sound = ({ sound }: SoundComponentProps) => {
  const { value } = sound;
  const { fps } = useVideoConfig();
  return (
    <Audio
      src={staticFile(`/assets/sounds/` + soundValues[value])}
      startFrom={5 * fps}
    />
  );
};

export default Sound;
