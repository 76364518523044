import type { ReactNode } from "react";
import styled from "styled-components";

const FlexContainer = styled.div`
  display: flex;
`;

interface FlexProps {
  children: ReactNode;
}
const Flex = ({ children }: FlexProps) => {
  return <FlexContainer>{children}</FlexContainer>;
};

export default Flex;
