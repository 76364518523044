import { useContext } from "react";
import { FilesContext } from "../FilesContext";
import { AnimatedObject } from "./base/AnimatedObject";
import Image from "./Image";

export interface ImageProps {
  imageType: "url" | "id";
  value: string;
  width?: number;
  height?: number;
  borderRound?: boolean;
  zoomAnimation?: boolean;
  withShadow?: boolean;
}

const ImageAnimation = ({ props }: { props: ImageProps }) => {
  const { files } = useContext(FilesContext);
  const getSrc = (props: ImageProps) => {
    if (props.imageType === "url") {
      return props.value;
    } else if (props.imageType === "id") {
      return files[props.value]?.value ?? null;
    }
    return "";
  };

  const { width, height, borderRound, zoomAnimation, withShadow } = props;
  const path = getSrc(props);
  let result = (
    <Image
      props={{ path, width, height, borderRound, zoomAnimation, withShadow }}
    />
  );
  return result;
};

export default ImageAnimation;
